import { sanityClient, urlFor } from "../lib/sanity";
import { useState, useEffect } from "react";
import { PortableText } from "@portabletext/react";
import Carousel from "react-multi-carousel";
import CustomDot from "../utils/CustomDot";
import { Link } from "react-router-dom";

const Welcome = () => {
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "banner"] | order(_createdAt desc) {
				title,
				slug,
				bannerImage,
				tagLine,
				context
			}`
      )
      .then((data) => setBanner(data[0]))
      .catch(console.error);
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 768, min: 640 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <>
      {banner && (
        <div className="lg:h-[35rem] relative">
          <Carousel
            arrows={false}
            autoPlay
            autoPlaySpeed={9000}
            infinite
            pauseOnHover
            slidesToSlide={1}
            swipeable
            responsive={responsive}
            showDots
            customDot={<CustomDot />}
            className="w-full lg:h-[35rem]"
          >
            {banner.bannerImage &&
              banner.bannerImage.map(({ bImage }, idx) => (
                <img
                  src={urlFor(bImage).url()}
                  alt="banner_img"
                  className="object-cover"
                  key={idx}
                />
              ))}
          </Carousel>

          <div
            className={
              banner.context
                ? "absolute bottom-12 lg:bottom-32 left-6 lg:left-[10rem] 2xl:left-[27rem] lg:w-fit"
                : "hidden"
            }
          >
            <div className="max-w-5xl mx-auto px-3 py-3">
              <h1 className="text-xl sm:text-4xl lg:text-6xl font-bold text-white font-inter leading-none">
                Refreshing and Affordable{" "}
                <span className="hover:text-highblu underline transition-colors duration-300 ease-in-out">
                  <Link to="/products/highlands-water">Water</Link>
                </span>
                ,
                <span className="hover:text-highor underline transition-colors duration-300 ease-in-out">
                  <Link to="/products/highlands-cordials"> Cordials</Link>
                </span>
                ,{" "}
                <span className="hover:text-[#0A8437] underline transition-colors duration-300 ease-in-out">
                  <Link to="/products/club">Soft Drinks</Link>
                </span>{" "}
                and{" "}
                <span className="hover:text-[#030AAD] underline transition-colors duration-300 ease-in-out">
                  <Link to="/products/bazuu-energy">Energy Drinks</Link>
                </span>
              </h1>
              <div className="text-white z-30 text-xs sm:text-xl mt-3 lg:mt-10 w-3/4 font-inter">
                <PortableText value={banner.tagLine} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Welcome;
