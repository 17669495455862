import ClientConfig from "@sanity/client";
import { PortableText as PortableTextComponent } from "@portabletext/react";
import createImageUrlBuilder from "@sanity/image-url";
import { config } from "./config";

export const sanityClient = ClientConfig(config);

export const urlFor = (source) => createImageUrlBuilder(config).image(source);

export const PortableText = (props) => (
	<PortableTextComponent components={{}} {...props} />
);
