import { memo, useContext, useEffect, useState } from "react";
import { ProductContext } from "../context/productContext";
import { sanityClient, urlFor } from "../lib/sanity";
import { PortableText } from "@portabletext/react";
import { Backdrop, Box, Fade, Modal } from "@mui/material";
import { FaTimes } from "react-icons/fa";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid #d1d5db",
  boxShadow: 24,
  p: 4,
};

const ProdIngrModal = ({ slug }) => {
  const { showProdModal, dispatch } = useContext(ProductContext);
  const [nutrients, setNutrients] = useState(null);

  const handleClose = () => {
    dispatch({ type: "HIDEPRODMODAL" });
  };

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "product" && slug.current == "${slug}"]{
					...,
					productsContent[]->,
				refBrand->,
			}`
      )
      .then((data) => setNutrients(data))
      .catch(console.error);
  }, [slug]);

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      open={showProdModal}
      onClose={handleClose}
      className="mx-5 xl:mx-0"
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={showProdModal}>
        <Box
          sx={style}
          className="w-full h-[40rem] 2xl:h-[46rem] lg:w-[60rem] overflow-y-scroll"
        >
          {nutrients &&
            nutrients.map(
              (
                { refBrand, sumImage, ingredientImage, productsContent },
                idx
              ) => (
                <div className="relative flex flex-col" key={idx}>
                  <img
                    src={urlFor(refBrand.mainImage).url()}
                    alt="brand_img"
                    className="h-[3rem] lg:h-[5rem] mx-auto"
                  />
                  <FaTimes
                    className="absolute top-0 right-0 cursor-pointer"
                    onClick={handleClose}
                  />
                  <div className="flex flex-col lg:flex-row justify-center gap-x-10 mt-10">
                    {slug.includes("cordial") ? (
                      <img
                        src={urlFor(ingredientImage).url()}
                        alt="product_img"
                        className="h-[10rem] lg:h-[25rem] object-contain lg:object-cover"
                      />
                    ) : (
                      <img
                        src={urlFor(sumImage).url()}
                        alt="prod_img"
                        className="h-[10rem] lg:h-[25rem] object-contain lg:object-cover"
                      />
                    )}
                    {!slug.includes("cordial") && (
                      <div className="w-full lg:w-max">
                        <h1 className="font-bold text-sm lg:text-lg uppercase">
                          Nutritional Facts:
                        </h1>
                        {slug.includes("mineral-water") ? (
                          <p className="font-semibold border-b text-xs lg:text-base border-b-gray-400">
                            Average mineral composition in mg/litre as per KS
                            459-1:2007
                          </p>
                        ) : (
                          <p className="font-semibold border-b text-xs lg:text-base border-b-gray-400">
                            Average nutritional value in 100ml
                          </p>
                        )}
                        <div className="divide-y divide-gray-400 overflow-y-auto">
                          {productsContent[0].nutritionalInfo &&
                            productsContent[0].nutritionalInfo.map(
                              ({ amount, nutrient }, idx) => (
                                <div
                                  className="flex items-center justify-between text-sm lg:text-sm py-0.5"
                                  key={idx}
                                >
                                  <p>{nutrient}</p>
                                  <p>{amount}</p>
                                </div>
                              )
                            )}
                        </div>
                        {!slug.includes("mineral-water") && (
                          <p className="text-sm lg:text-base font-semibold">
                            % of an adult's guideline daily amount (based on a
                            2,000 kcal diet)
                          </p>
                        )}
                        <div className="mt-3 text-sm lg:text-base">
                          {!slug.includes("mineral-water") && (
                            <h1 className="font-bold text-lg py-2">
                              {`per ${productsContent[0].servingAmount} serving`}
                            </h1>
                          )}
                          <div className="flex gap-x-2">
                            {productsContent[0].serving &&
                              productsContent[0].serving.map(
                                ({ amount, nutrient, percentage }, idx) => (
                                  <div
                                    className="flex items-center justify-between gap-x-1 border border-black w-max rounded-full"
                                    key={idx}
                                  >
                                    <div className="pl-2 font-semibold text-xxs lg:text-xs">
                                      <p>{nutrient}</p>
                                      <p>{amount}</p>
                                    </div>
                                    <p className="text-white bg-black rounded-full h-[1.5rem] w-[1.5rem] lg:h-[2.4rem] lg:w-[2.4rem] text-center pt-1 lg:pt-2 text-xxs lg:text-[.7rem]">
                                      {percentage}
                                    </p>
                                  </div>
                                )
                              )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {!slug.includes("mineral-water") && (
                    <div className="py-5 mt-5 lg:w-full">
                      <h1 className="font-bold text-lg">Ingredients</h1>
                      <div className="font-medium text-sm">
                        <PortableText value={productsContent[0].ingredients} />
                      </div>
                    </div>
                  )}
                </div>
              )
            )}
        </Box>
      </Fade>
    </Modal>
  );
};

export default memo(ProdIngrModal);
