import React, { memo } from 'react'
import nothing from "../../static/nothing_found.svg";
import { useParams } from 'react-router-dom';

const NoArticle = () => {
    const { tag, slug } = useParams()

    return (
        <section className="">
            <div className="text-center space-y-5 mb-[5rem] border border-gray-300 rounded-xl py-5 px-3 xl:px-0 lg:w-3/4 mx-auto my-20">
                <img src={nothing} alt="nothing_found" className="w-[10rem] mx-auto" />
                <h1 className="capitalize font-bold font-inter text-xl lg:text-5xl text-highor">
                    No articles found!
                </h1>
                <p className="font-medium text-sm lg:text-lg font-inter lg:w-3/4 mx-auto text-gray-600">
                    Keep checking this page for articles on <span className='capitalize'>{tag.replaceAll("-", " ") || slug.replaceAll("-", " ")}</span>.
                </p>
            </div>
        </section>
    )
}

export default memo(NoArticle)