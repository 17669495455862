import { createContext, useReducer } from "react";
import eventsReducer from "./eventsReducer";

const INITIAL_STATE = {
	showEvents: false,
};

export const EventsContext = createContext(INITIAL_STATE);

export const EventsContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(eventsReducer, INITIAL_STATE);

	return (
		<EventsContext.Provider
			value={{
				showEvents: state.showEvents,
				dispatch,
			}}>
			{children}
		</EventsContext.Provider>
	);
};
