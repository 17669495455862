import Marquee from "react-fast-marquee";
import pacesetters from "../static/Pacesetters-Awards.png";
import beverage from "../static/Beverage-Awards.png";

const Awards = () => {
  return (
    <div className="my-[3rem]">
      <div className="max-w-9xl mx-auto px-5 xl:px-0">
        <div className="flex items-center justify-center w-1/2 mx-auto">
          <h1 className="text-center text-highblu font-inter font-black text-lg lg:text-3xl py-2 px-5">
            Our Awards
          </h1>
        </div>
        <Marquee
          loop={0}
          speed={60}
          pauseOnClick={true}
          gradient={false}
          className=""
        >
          <div className="grid grid-cols-5 gap-2 lg:gap-x-14">
            <div className="flex flex-col items-center justify-center w-[10rem]">
              <img
                className="h-[5rem] lg:h-[12rem] object-cover"
                src={beverage}
                alt="award"
              />
              <p className="font-bold text-highblu text-xs text-center">
                1st Runner Up - CLUB soda: Most Preferred Soda 2022
              </p>
            </div>
            <div className="flex flex-col items-center justify-center w-[10rem]">
              <img
                className="h-[5rem] lg:h-[12rem] object-cover"
                src={beverage}
                alt="award"
              />
              <p className="font-bold text-highblu text-xs text-center">
                Winner - Cordials: Most Preferred Dilute to Taste Juice 2022
              </p>
            </div>
            <div className="flex flex-col items-center justify-center w-[10rem]">
              <img
                className="h-[5rem] lg:h-[12rem] object-cover"
                src={beverage}
                alt="award"
              />
              <p className="font-bold text-highblu text-xs text-center">
                1st Runner Up Most Preferred Emerging cola 2021/22
              </p>
            </div>
            <div className="flex flex-col items-center justify-center w-[10rem]">
              <img
                className="h-[5rem] lg:h-[12rem] object-cover"
                src={beverage}
                alt="award"
              />
              <p className="font-bold text-highblu text-xs text-center">
                1st Runner Up Most Preferred Spring Water 2021/22
              </p>
            </div>
            <div className="flex flex-col items-center justify-center w-[10rem]">
              <img
                className="h-[5rem] lg:h-[12rem] object-cover"
                src={pacesetters}
                alt="award"
              />
              <p className="font-bold text-highblu text-xs text-center">
                Winner - Highlands Drinks LTD - Pacesetters In Beverage Industry
                2022
              </p>
            </div>
          </div>
        </Marquee>
      </div>
    </div>
  );
};

export default Awards;
