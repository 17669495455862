import { createContext, useReducer } from "react";
import discoveryReducer from "./discoveryReducer";

const INITIAL_STATE = {
	showDiscovery: true,
	showNews: false,
	showEvents: false,
};

export const DiscoveryContext = createContext(INITIAL_STATE);

export const DiscoveryContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(discoveryReducer, INITIAL_STATE);

	return (
		<DiscoveryContext.Provider
			value={{
				showDiscovery: state.showDiscovery,
				showNews: state.showNews,
				showEvents: state.showEvents,
				dispatch,
			}}>
			{children}
		</DiscoveryContext.Provider>
	);
};
