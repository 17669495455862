import { Backdrop, Box, Fade, Modal } from "@mui/material";
import React from "react";
import Carousel from "react-multi-carousel";
import CustomDot from "../../utils/CustomDot";
import { urlFor } from "../../lib/sanity";

// screen size
const isSmallScreen = window.matchMedia("(max-width: 600px)").matches;
const isMediumScreen = window.matchMedia("(max-width: 1024px)").matches;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "50%",
  width: isSmallScreen ? 350 : isMediumScreen ? 650 : 800,
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const ImageModal = ({ open, handleClose, funImages }) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 768, min: 640 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          {funImages && (
            <Box sx={style}>
              <Carousel
                arrows
                infinite
                pauseOnHover
                slidesToSlide={1}
                swipeable
                responsive={responsive}
                showDots
                customDot={<CustomDot />}
                className="w-full h-full"
              >
                {funImages[0].funImage &&
                  funImages[0].funImage.map(({ fImage }, idx) => (
                    <img
                      src={urlFor(fImage).url()}
                      alt="banner_img"
                      className="object-contain w-full h-full"
                      key={idx}
                    />
                  ))}
              </Carousel>
            </Box>
          )}
        </Fade>
      </Modal>
    </div>
  );
};

export default ImageModal;
