import React, { useEffect } from "react";
import heritage_img from "../static/padia.jpg";
import { Fade } from "react-reveal";
import Fcta from "../components/Fcta";
import Links from "../components/Links";
import highlands from "../static/heritage/1954-Highlands.png";
import babito from "../static/heritage/1978-Babito.png";
import mirinda from "../static/heritage/1980s-Mirinda.png";
import water from "../static/heritage/1991-Mineral-water.png";
import cordial from "../static/heritage/1998-Cordial-EXTs.png";
import plant from "../static/heritage/2002-Plant-Upgrade.png";
import csds from "../static/heritage/2009-CSDs.png";
import club from "../static/heritage/2014-CLUB-Soda.png";
import flavors from "../static/heritage/2016-New-Flavors.png";
import rebrand from "../static/heritage/2018-Rebrand.png";
import bazuu from "../static/heritage/2021-Bazuu-Energy.png";
import softa from "../static/heritage/1960---Softa---Vimto.png";
import rebrand1 from "../static/heritage/2023---CLUB-Rebranding.png";
import royal from "../static/heritage/2023 Royal CLUB.png";

const Heritage = () => {
	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	return (
		<div className="">
			<div className="h-[30rem] w-full relative">
				<div className="absolute top-0 left-0 w-full h-[30rem] bg-black bg-opacity-40"></div>
				<img
					src={heritage_img}
					alt="careers_img"
					className="h-[30rem] object-cover w-full"
					loading="eager"
				/>
				<div className="absolute top-0 left-0 w-full h-[30rem] bg-transparent grid place-items-center">
					<div className="text-white">
						<p className="uppercase font-black text-2xl lg:text-4xl xl:text-6xl 2xl:text-8xl text-center mb-5 z-10 relative after:block after:bg-white after:absolute after:-bottom-3 after:left-[4.2rem] lg:after:left-[7.8rem] xl:after:left-[12rem] 2xl:after:left-[29rem] 2xl:after:w-1/2 after:h-2 after:w-2/3">
							a story of passion & commitment
						</p>
						<p className="text-sm text-center uppercase font-medium xl:text-xl">
							Highlands' journey from a Nyeri store to a Beverage
							Industry Leader.
						</p>
					</div>
				</div>
			</div>
			<div className="bg-[url('/src/static/bg.jpg')] bg-contain relative">
				<Fade bottom cascade>
					<div className="max-w-4xl mx-auto flex flex-col lg:flex-row justify-between py-10 px-5 xl:px-0 overflow-hidden">
						<div className="lg:w-[25rem] float-left lg:absolute lg:-bottom-6 lg:right-[15rem] 2xl:right-[30rem]">
							<img
								className="rounded-lg h-[15rem] object-cover"
								src={heritage_img}
								alt="heritage_img"
							/>
						</div>
						<div className="lg:w-1/2">
							<h1 className="text-white font-bold text-3xl leading-none py-3">
								A 70-year-strong and undeniable Kenyan origin
							</h1>
							<p className="text-white text-sm lg:text-lg leading-6">
								Softa, Mirinda and Babito were soda brands that
								were well-known in the late 70s, in the 80s, and
								early 90s. To this day, anybody who lived in
								those times still remembers them. However,
								Highlands did not start our as a soft drinks
								maker but a shop in Nyeri in the late 1940s by
								the founder, Mr.Tribhovan Padia.
							</p>
						</div>
					</div>
				</Fade>
			</div>

			{/* The History */}
			<div className="">
				<Fade bottom cascade>
					<div className="flex justify-center overflow-hidden px-5 xl:px-0">
						<div className="relative">
							<h1 className="text-[3.5rem] lg:text-[12rem] font-black text-slate-300">
								1954
							</h1>
							<div className="absolute top-12 left-6 lg:top-44 lg:left-20">
								<h2 className="text-2xl lg:text-6xl font-bold text-highblu leading-none">
									Highlands
								</h2>
								<p className="text-xxs lg:text-lg">
									Highlands was incorporated in Nyeri because
									of the high quality of water available in
									the area.
								</p>
							</div>
						</div>
						<img
							src={highlands}
							alt="history_img"
							className="w-[10rem] h-[10rem] lg:w-[30rem] lg:h-[30rem] object-cover"
						/>
					</div>
				</Fade>
				<Fade bottom cascade>
					<div className="flex justify-center bg-[url('/src/static/bg.jpg')] bg-contain overflow-hidden px-5 xl:px-0">
						<img
							src={softa}
							alt="history_img"
							className="w-[10rem] h-[10rem] lg:w-[30rem] lg:h-[30rem] object-cover"
						/>
						<div className="relative">
							<h1 className="text-[3.5rem] lg:text-[12rem] font-black text-highblu">
								1960s
							</h1>
							<div className="absolute top-12 left-6 lg:top-44 lg:left-20 text-white">
								<h2 className="text-2xl lg:text-6xl font-bold leading-none">
									Softa & Vimto
								</h2>
								<p className="text-xxs lg:text-lg">
									Introduced Softa soft drink and acquired
									Vimto Franchise.
								</p>
							</div>
						</div>
					</div>
				</Fade>
				<Fade bottom cascade>
					<div className="flex justify-center overflow-hidden px-5 xl:px-0">
						<div className="relative">
							<h1 className="text-[3.5rem] lg:text-[12rem] font-black text-slate-300">
								1978
							</h1>
							<div className="absolute top-12 left-6 lg:top-44 lg:left-20">
								<h2 className="text-2xl lg:text-6xl font-bold text-highblu leading-none">
									Babito
								</h2>
								<p className="text-xxs lg:text-lg">
									Introduced Babito Sot Drink that went ahead
									to be a great success in the market.
								</p>
							</div>
						</div>
						<img
							src={babito}
							alt="history_img"
							className="w-[10rem] h-[10rem] lg:w-[30rem] lg:h-[30rem] object-cover"
						/>
					</div>
				</Fade>
				<Fade bottom cascade>
					<div className="flex justify-center bg-[url('/src/static/bg.jpg')] bg-contain overflow-hidden px-5 xl:px-0">
						<img
							src={mirinda}
							alt="history_img"
							className="w-[10rem] h-[10rem] lg:w-[30rem] lg:h-[30rem] object-cover"
						/>
						<div className="relative">
							<h1 className="text-[3.5rem] lg:text-[12rem] font-black text-highblu">
								1980s
							</h1>
							<div className="absolute top-12 left-6 lg:top-44 lg:left-20 text-white">
								<h2 className="text-2xl lg:text-6xl font-bold leading-none">
									Mirinda & Cordials
								</h2>
								<p className="text-xxs lg:text-lg">
									Became the official bottler for Pepsi in
									1986, acquired the well-known Mirinda
									Franchise in 1987, and launched Highlands
									Orange Cordial in 1989.
								</p>
							</div>
						</div>
					</div>
				</Fade>
				<Fade bottom cascade>
					<div className="flex justify-center overflow-hidden px-5 xl:px-0">
						<div className="relative">
							<h1 className="text-[3.5rem] lg:text-[12rem] font-black text-slate-300">
								1991
							</h1>
							<div className="absolute top-12 left-6 lg:top-44 lg:left-20">
								<h2 className="text-2xl lg:text-6xl font-bold text-highblu leading-none">
									Mineral Water
								</h2>
								<p className="text-xxs lg:text-lg">
									Officially began bottling mineral water.
									Introduced Pineapple Cordial.
								</p>
							</div>
						</div>
						<img
							src={water}
							alt="history_img"
							className="w-[10rem] h-[10rem] lg:w-[30rem] lg:h-[30rem] object-cover"
						/>
					</div>
				</Fade>
				<Fade bottom cascade>
					<div className="flex justify-center bg-[url('/src/static/bg.jpg')] bg-contain overflow-hidden px-5 xl:px-0">
						<img
							src={cordial}
							alt="history_img"
							className="w-[10rem] h-[10rem] lg:w-[30rem] lg:h-[30rem] object-cover"
						/>
						<div className="relative">
							<h1 className="text-[3.5rem] lg:text-[12rem] font-black text-highblu">
								1998
							</h1>
							<div className="absolute top-12 left-6 lg:top-44 lg:left-20 text-white">
								<h2 className="text-2xl lg:text-6xl font-bold leading-none">
									Cordial Extensions
								</h2>
								<p className="text-xxs lg:text-lg">
									Extended the cordial range with 2 new
									flavors; tropical and lemon cordials.
								</p>
							</div>
						</div>
					</div>
				</Fade>
				<Fade bottom cascade>
					<div className="flex justify-center overflow-hidden px-5 xl:px-0">
						<div className="relative">
							<h1 className="text-[3.5rem] lg:text-[12rem] font-black text-slate-300">
								2002
							</h1>
							<div className="absolute top-12 left-6 lg:top-44 lg:left-20">
								<h2 className="text-2xl lg:text-6xl font-bold text-highblu leading-none">
									Plant Upgrade
								</h2>
								<p className="text-xxs lg:text-lg">
									Opened up new facilities with new bottling
									lines.
								</p>
							</div>
						</div>
						<img
							src={plant}
							alt="history_img"
							className="w-[10rem] h-[10rem] lg:w-[30rem] lg:h-[30rem] object-cover"
						/>
					</div>
				</Fade>
				<Fade bottom cascade>
					<div className="flex justify-center bg-[url('/src/static/bg.jpg')] bg-contain overflow-hidden px-5 xl:px-0">
						<img
							src={csds}
							alt="history_img"
							className="w-[10rem] h-[10rem] lg:w-[30rem] lg:h-[30rem] object-cover"
						/>
						<div className="relative">
							<h1 className="text-[3.5rem] lg:text-[12rem] font-black text-highblu">
								2009
							</h1>
							<div className="absolute top-12 left-6 lg:top-44 lg:left-20 text-white">
								<h2 className="text-2xl lg:text-6xl font-bold leading-none">
									Plant Upgrade
								</h2>
								<p className="text-xxs lg:text-lg">
									Begun R&D on 3 new Carbonated Soft Drinks.
								</p>
							</div>
						</div>
					</div>
				</Fade>
				<Fade bottom cascade>
					<div className="flex justify-center overflow-hidden px-5 xl:px-0">
						<div className="relative">
							<h1 className="text-[3.5rem] lg:text-[12rem] font-black text-slate-300">
								2014
							</h1>
							<div className="absolute top-12 left-6 lg:top-44 lg:left-20">
								<h2 className="text-2xl lg:text-6xl font-bold text-highblu leading-none">
									Club Soda
								</h2>
								<p className="text-xxs lg:text-lg">
									Launched Club soda with 3 flavors.
								</p>
							</div>
						</div>
						<img
							src={club}
							alt="history_img"
							className="w-[10rem] h-[10rem] lg:w-[30rem] lg:h-[30rem] object-cover"
						/>
					</div>
				</Fade>
				<Fade bottom cascade>
					<div className="flex justify-center bg-[url('/src/static/bg.jpg')] bg-contain overflow-hidden px-5 xl:px-0">
						<img
							src={flavors}
							alt="history_img"
							className="w-[10rem] h-[10rem] lg:w-[30rem] lg:h-[30rem] object-cover"
						/>
						<div className="relative">
							<h1 className="text-[3.5rem] lg:text-[12rem] font-black text-highblu">
								2016
							</h1>
							<div className="absolute top-12 left-6 lg:top-44 lg:left-20 text-white">
								<h2 className="text-2xl lg:text-6xl font-bold leading-none">
									New Flavors
								</h2>
								<p className="text-xxs lg:text-lg">
									Introduced our signature flavors -
									Blackcurrant, Pineapple and Passion
								</p>
							</div>
						</div>
					</div>
				</Fade>
				<Fade bottom cascade>
					<div className="flex justify-center overflow-hidden px-5 xl:px-0">
						<div className="relative">
							<h1 className="text-[3.5rem] lg:text-[12rem] font-black text-slate-300">
								2018/19
							</h1>
							<div className="absolute top-14 left-6 lg:top-44 lg:left-20">
								<h2 className="text-2xl lg:text-6xl font-bold text-highblu leading-none">
									CLUB: The Rebrand
								</h2>
								<p className="text-xxs lg:text-lg">
									Rebranded CSD to CLUB in 2018 and introduced
									3 new flavors, i.e Apple, Mango and Pina
									Colada.
								</p>
							</div>
						</div>
						<img
							src={rebrand}
							alt="history_img"
							className="w-[10rem] h-[10rem] lg:w-[30rem] lg:h-[30rem] object-cover"
						/>
					</div>
				</Fade>
				<Fade bottom cascade>
					<div className="flex justify-center bg-[url('/src/static/bg.jpg')] bg-contain overflow-hidden px-5 xl:px-0">
						<img
							src={bazuu}
							alt="history_img"
							className="w-[10rem] h-[10rem] lg:w-[30rem] lg:h-[30rem] object-cover"
						/>
						<div className="relative">
							<h1 className="text-[3.5rem] lg:text-[12rem] font-black text-highblu">
								2021
							</h1>
							<div className="absolute top-12 left-6 lg:top-44 lg:left-20 text-white">
								<h2 className="text-2xl lg:text-6xl font-bold leading-none">
									Bazuu Energy
								</h2>
								<p className="text-xxs lg:text-lg">
									Launch of BAZUU Energy drink 265ml & 500ml
								</p>
							</div>
						</div>
					</div>
				</Fade>
				<Fade bottom cascade>
					<div className="flex justify-center overflow-hidden px-5 xl:px-0">
						<div className="relative">
							<h1 className="text-[3.5rem] lg:text-[12rem] font-black text-slate-300">
								2023
							</h1>
							<div className="absolute top-12 left-6 lg:top-44 lg:left-20">
								<h2 className="text-2xl lg:text-6xl font-bold text-highblu leading-none">
									CLUB Rebranding
								</h2>
								<p className="text-xxs lg:text-lg">
									Rebranding of all CLUB Flavours
								</p>
							</div>
						</div>
						<img
							src={rebrand1}
							alt="history_img"
							className="w-[10rem] h-[10rem] lg:w-[30rem] lg:h-[30rem] object-contain"
						/>
					</div>
				</Fade>
				<Fade bottom cascade>
					<div className="flex justify-center bg-[url('/src/static/bg.jpg')] bg-contain overflow-hidden px-5 xl:px-0">
						<img
							src={royal}
							alt="history_img"
							className="w-[10rem] h-[10rem] lg:w-[30rem] lg:h-[30rem] object-cover"
						/>
						<div className="relative">
							<h1 className="text-[3.5rem] lg:text-[12rem] font-black text-highblu">
								2023
							</h1>
							<div className="absolute top-12 left-6 lg:top-44 lg:left-20 text-white">
								<h2 className="text-2xl lg:text-6xl font-bold leading-none">
									Royal CLUB
								</h2>
								<p className="text-xxs lg:text-lg">
									Inception of Royal CLUB
								</p>
							</div>
						</div>
					</div>
				</Fade>
			</div>
			<Fcta />
			<Links />
		</div>
	);
};

export default Heritage;
