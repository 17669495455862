import { memo, useContext } from "react";
import { Link } from "react-router-dom";
import { BrandContext } from "../context/brandContext";
import club from "../static/logos/CLUB-2.png";
import royal from "../static/logos/royal.png";
import cordial from "../static/logos/CORDIALS.png";
import bazuu from "../static/logos/BAZUU.png";
import water from "../static/logos/HL-WATER.png";
import { SiProducthunt } from "react-icons/si";
import { MdOutlineHistory } from "react-icons/md";

const BrandMenu = ({ menuRef }) => {
  const { showBrandMenu, dispatch } = useContext(BrandContext);

  const handleBrandMenu = () => {
    dispatch({ type: "HIDEBRANDNAV" });
  };

  return (
    <div
      className={
        showBrandMenu
          ? "absolute top-[2.5rem] left-0 rounded-xl w-[18rem] bg-gray-100 text-highblu hidden lg:block shadow-lg"
          : "hidden"
      }
      ref={menuRef}
    >
      <div className="grid grid-cols-1 gap-y-2 font-medium p-2">
        <Link
          to="/products/highlands-water"
          className="flex items-center hover:bg-highblu hover:text-white transition-all duration-300 ease-in-out py-3 rounded"
          onClick={handleBrandMenu}
        >
          <div className="flex items-center px-2 gap-x-2">
            <img
              src={water}
              alt="club_logo"
              className="h-[2rem] w-[2rem] object-contain rounded-full"
            />
            <p className="uppercase text-xs">Highlands Water</p>
          </div>
        </Link>
        <Link
          to="/products/highlands-cordials"
          className="flex items-center hover:bg-highblu hover:text-white transition-all duration-300 ease-in-out py-3 rounded"
          onClick={handleBrandMenu}
        >
          <div className="flex items-center px-2 gap-x-2">
            <img
              src={cordial}
              alt="club_logo"
              className="h-[2rem] w-[2rem] object-contain rounded-full"
            />
            <p className="uppercase text-xs">Highlands Cordials</p>
          </div>
        </Link>
        <Link
          to="/products/club"
          className="flex items-center hover:bg-highblu hover:text-white transition-all duration-300 ease-in-out py-3 rounded"
          onClick={handleBrandMenu}
        >
          <div className="flex items-center px-2 gap-x-2">
            <img
              src={club}
              alt="club_logo"
              className="h-[2rem] w-[2rem] rounded-full"
            />
            <p className="uppercase text-xs">CLUB</p>
          </div>
        </Link>
        <Link
          to="/products/bazuu-energy"
          className="flex items-center hover:bg-highblu hover:text-white transition-all duration-300 ease-in-out py-3 rounded"
          onClick={handleBrandMenu}
        >
          <div className="flex items-center px-2 gap-x-2">
            <img
              src={bazuu}
              alt="club_logo"
              className="h-[2rem] w-[2rem] object-contain rounded-full"
            />
            <p className="uppercase text-xs">Bazuu Energy</p>
          </div>
        </Link>
        <Link
          to="/products/royal-club"
          className="flex items-center hover:bg-highblu hover:text-white transition-all duration-300 ease-in-out py-3 rounded"
          onClick={handleBrandMenu}
        >
          <div className="flex items-center px-2 gap-x-2">
            <img
              src={royal}
              alt="club_logo"
              className="h-[2rem] w-[2rem] rounded-full"
            />
            <p className="uppercase text-xs">Royal CLUB</p>
          </div>
        </Link>
      </div>
      <div className="flex bg-gray-200 justify-between divide-x divide-gray-300 rounded-b-xl">
        <Link
          to="/products"
          className="py-2 px-5 font-inter font-bold flex items-center gap-x-2 hover:bg-highblu hover:text-white rounded-bl-xl transition-all duration-150 ease-out flex-1"
          onClick={handleBrandMenu}
        >
          <SiProducthunt size={17} />
          All products
        </Link>
        <Link
          to="/our-heritage"
          className="py-2 px-5 font-inter font-bold flex items-center gap-x-1 hover:bg-highblu hover:text-white transition-all duration-150 ease-out rounded-br-xl flex-1"
          onClick={handleBrandMenu}
        >
          <MdOutlineHistory size={19} />
          Our story
        </Link>
      </div>
    </div>
  );
};

export default memo(BrandMenu);
