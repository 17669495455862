import React, { memo, useEffect, useState } from "react";
import { sanityClient } from "../lib/sanity";
import { Link, useParams } from "react-router-dom";

const RelatedArticle = ({ tag }) => {
  const [articles, setArticles] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "news" && tag->.name == "${tag}" && slug.current != "${slug}"] | order(_updatedAt desc)[0...10]{
					_updatedAt,
				title,
				slug,
				tag->,
			}`
      )
      .then((data) => setArticles(data))
      .catch(console.error);
  }, [tag, slug]);

  return (
    <div>
      {articles &&
        articles.map(({ slug, title, tag }) => (
          <div className="divide-y divide-gray-300 border-y border-y-gray-300">
            <Link to={`/news-events/article/${slug.current}`}>
              <div className="lg:w-[18.5rem] py-4">
                <Link to={`/news-events/${tag.slug.current}`} className="w-max">
                  <p className="font-semibold text-xs text-highor italic">
                    {tag.name}
                  </p>
                </Link>
                <p className="text-sm font-medium line-clamp-2 hover:text-highblu">
                  {title}
                </p>
              </div>
            </Link>
          </div>
        ))}
    </div>
  );
};

export default memo(RelatedArticle);
