import careers_img from "../static/career.jpg";
import Job from "../components/Job";
import Departments from "../components/Departments";
import JobCategory from "../components/JobCategory";
import { useEffect } from "react";
import CareerFun from "../components/CareerFun";

const Careers = () => {
	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	return (
		<div className="">
			<div className="h-[20rem] sm:h-[30rem] w-full relative">
				<div className="absolute top-0 left-0 w-full h-[20rem] sm:h-[30rem] bg-black bg-opacity-20"></div>
				<img
					src={careers_img}
					alt="careers_img"
					className="h-[20rem] sm:h-[30rem] object-cover w-full"
					loading="eager"
				/>
				<div className="absolute top-0 left-0 w-full h-[20rem] sm:h-[30rem] bg-transparent grid place-items-center">
					<p className="uppercase font-black text-2xl lg:text-4xl xl:text-6xl 2xl:text-8xl text-center text-white mb-5 z-10 relative after:block after:bg-white after:absolute after:-bottom-3 after:left-12 lg:after:left-[4rem] xl:after:left-[5.5rem] 2xl:after:left-[13.5rem] 2xl:after:w-1/2 after:h-2 after:w-2/3">
						Working with us
					</p>
				</div>
			</div>
			<div className="max-w-5xl mx-auto w-full py-10">
				<div className="px-10">
					<p className="capitalize font-bold font-inter text-3xl lg:text-5xl text-highblu">
						be part of our mission
					</p>
					<p className="text-lg font-medium pt-2 lg:w-3/4 text-gray-600">
						We are looking for passionate people to join us on our
						mission. We value flat heirarchies, clear communication,
						and full ownership and responsibility.
					</p>
				</div>
				<Departments />
				<JobCategory />
				<div className="mt-[5rem] bg-gray-100 py-4">
					<div className="text-center space-y-5 mb-[5rem]">
						<h1 className="capitalize font-bold font-inter text-2xl lg:text-5xl text-highblu">
							recent job openings
						</h1>
						<p className="font-medium text-lg font-inter w-3/4 mx-auto text-gray-600">
							Want to come and work with us and be part of
							Highlands Drinks Limited, to provide great taste and
							great value to our customers?
						</p>
					</div>
					<div className="px-10 divide-y divide-gray-400">
						<Job />
					</div>
				</div>
				<div className="mt-[5rem] px-10">
					<div className="text-center space-y-4 mb-4">
						<h1 className="font-bold font-inter text-2xl lg:text-4xl text-highblu capitalize">
							Oh, and it's not only about work!
						</h1>
						<p className="lg:w-3/4 mx-auto text-sm lg:text-lg text-gray-500">
							As Highlands Drinks Limited, fostering a vibrant and
							enjoyable workplace culture is not just a goal; it's
							a way of life. The organization believes that a
							happy and engaged team is a productive one.
						</p>
					</div>
					<CareerFun />
				</div>
			</div>
		</div>
	);
};

export default Careers;
