import { getImageDimensions } from "@sanity/asset-utils";
import urlBuilder from "@sanity/image-url";
import { config } from "../lib/config";

const ImageComponent = ({ value, isInline }) => {
  const { width, height } = getImageDimensions(value);
  return (
    <img
      src={urlBuilder(config)
        .image(value)
        .width(isInline ? 100 : 800)
        .fit("max")
        .auto("format")
        .url()}
      alt={value.alt || " "}
      loading="lazy"
      style={{
        display: isInline ? "inline-block" : "block",
        aspectRatio: width / height,
      }}
    />
  );
};

export const serializers = {
  types: {
    image: ImageComponent,
  },
  block: {
    h1: ({ children }) => <h1 className="text-4xl">{children}</h1>,
    h2: ({ children }) => <h1 className="text-3xl">{children}</h1>,
    h3: ({ children }) => <h1 className="text-2xl">{children}</h1>,
    h4: ({ children }) => <h1 className="text-xl">{children}</h1>,
    normal: ({ children }) => {
      if (children.length === 1 && children[0] === "") {
        return <br />;
      }
      return <p>{children}</p>;
    },
    blockquote: ({ children }) => (
      <blockquote className="text-[#3A5077]">{children}</blockquote>
    ),
  },
  list: {
    // Ex. 1: customizing common list types
    bullet: ({ children }) => <ul className="mt-xl">{children}</ul>,
    number: ({ children }) => <ol className="mt-lg">{children}</ol>,

    // Ex. 2: rendering custom lists
    checkmarks: ({ children }) => (
      <ol className="m-auto text-lg">{children}</ol>
    ),
  },
  listItem: {
    // Ex. 1: customizing common list types
    bullet: ({ children }) => (
      <li style={{ listStyleType: "disclosure-closed" }} className="ml-[4rem]">
        {children}
      </li>
    ),

    // Ex. 2: rendering custom list items
    checkmarks: ({ children }) => <li>✅ {children}</li>,
  },
  marks: {
    link: ({ value, children }) => {
      return (
        <a href={value?.href} className="text-highor" rel="noreferrer noopener">
          {children}
        </a>
      );
    },
  },
};
