import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { sanityClient } from "../lib/sanity";
import { Link } from "react-router-dom";
import Loader from "./mini/Loader";
import ProductCard from "./mini/ProductCard";

const Royal = ({ brand }) => {
  const [products, setProducts] = useState();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "product"] | order(_createdAt desc){
            ...,
			flavour->,
			overlayColor->,
            refBrand->,
            productsContent[]->,
        }`
      )
      .then((data) => setProducts(data));
  }, []);

  if (!products) {
    return <Loader />;
  }

  return (
    <div className="px-5 xl:px-0">
      <div className="mb-[5rem] py-5 flex flex-col-reverse lg:flex lg:flex-row">
        <Sidebar brand="royal" />
        <div className="grid grid-cols-2 md:grid-cols-4 gap-2 lg:gap-5 max-w-5xl lg:mx-auto xl:px-0 flex-1">
          {products &&
            products
              .filter((product) => product.refBrand.slug.current === brand)
              .map(({ title, slug, sumImage, refBrand }, idx) => (
                <Link
                  to={`/products/${refBrand.slug.current}/${slug.current}`}
                  className=""
                  key={idx}
                >
                  <ProductCard
                    title={title}
                    slug={slug}
                    sumImage={sumImage}
                    refBrand={refBrand}
                  />
                </Link>
              ))}
        </div>
      </div>
    </div>
  );
};

export default Royal;
