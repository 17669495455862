import faq from "../static/faq-Slide.jpg";
import { useState, useEffect } from "react";
import { sanityClient } from "../lib/sanity";
import FAQAccordion from "../components/mini/FAQAccordion";

const FAQ = () => {
	const [query, setQuery] = useState(null);

	useEffect(() => {
		sanityClient
			.fetch(
				`*[_type == "faq"] | order(_createdAt desc){
				_id,
				question,
				answer,
				slug
			}`
			)
			.then((data) => setQuery(data))
			.catch(console.error);
	}, []);

	return (
		<div className="">
			<div className="h-[20rem] sm:h-[30rem] w-full relative">
				<div className="absolute top-0 left-0 w-full h-[20rem] sm:h-[30rem] bg-transparent grid place-items-center">
					<p className="flex items-center uppercase font-black text-2xl lg:text-4xl xl:text-6xl 2xl:text-8xl text-center text-white mb-5 z-10 relative after:block after:bg-white after:absolute after:-bottom-3 after:left-2 lg:after:left-[1rem] xl:after:left-[1.9rem] 2xl:after:left-[3.5rem] 2xl:after:w-1/2 after:h-2 after:w-2/3">
						FAQ'<span className="lowercase">s</span>
					</p>
				</div>
				<img
					src={faq}
					alt="faq_img"
					className="h-[20rem] sm:h-[30rem] w-full object-cover"
					loading="eager"
				/>
			</div>
			<div className="max-w-5xl mx-auto w-full mt-10 px-10 xl:px-0">
				<h1 className="text-highblu md:text-2xl lg:text-4xl font-bold">
					Frequently asked questions (FAQs)
				</h1>
				<div className="py-3 lg:py-10">
					{query &&
						query.map(({ question, answer, _id }, idx) => (
							<FAQAccordion
								question={question}
								answer={answer}
								idx={_id}
								key={idx}
							/>
						))}
				</div>
			</div>
		</div>
	);
};

export default FAQ;
