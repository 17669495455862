import React from "react";

const CustomDot = ({ onClick, active, index, carouselState }) => {
	// const { currentSlide } = carouselState;
	return (
		<li className={active ? "mb-5" : "mb-5"}>
			<button
				className={
					active
						? "bg-white h-1 w-20 mb-5 rounded-lg mr-2"
						: "bg-stone-900 h-1 w-10 mb-5 rounded-lg mr-2"
				}
				onClick={() => onClick()}
			/>
		</li>
	);
};

export default CustomDot;
