const navReducer = (state, action) => {
	switch (action.type) {
		case "SHOW": {
			return {
				showNav: true,
			};
		}
		case "HIDE": {
			return {
				showNav: false,
			};
		}
		default: {
			return {
				showNav: !state.showNav,
			};
		}
	}
};

export default navReducer;
