const newsReducer = (state, action) => {
	switch (action.type) {
		case "SHOW": {
			return {
				showNews: true,
			};
		}
		case "HIDE": {
			return {
				showNews: false,
			};
		}
		default: {
			return {
				showNews: !state.showNews,
			};
		}
	}
};

export default newsReducer;
