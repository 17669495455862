import React, { useContext, memo } from "react";
import { DiscoveryContext } from "../context/discoveryContext";
import { useState } from "react";
import { useEffect } from "react";
import { sanityClient, urlFor } from "../lib/sanity";
import { HashLink } from "react-router-hash-link";
import { HFilledButton } from "./mini/HButton";

const Discovery = () => {
  const { showDiscovery } = useContext(DiscoveryContext);
  const [karibu, setKaribu] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "highlandsKaribu"][0...3] | order(hierarchy asc){
                title,
				hierarchy,
                slug,
                image
            }`
      )
      .then((data) => setKaribu(data))
      .catch(console.error);
  }, []);

  return (
    <div className={showDiscovery ? "space-y-8 block" : "hidden"}>
      <h1 className="lg:text-white font-semibold lg:w-auto text-sm sm:text-xl text-center px-5 xl:px-0">
        Building a personal relationship with all our customers to ensure their
        needs are satisfied and feel part our family.
      </h1>
      <div className="grid grid-cols-2 gap-y-5 gap-x-5 lg:flex items-center lg:gap-x-5 px-5 xl:px-0">
        {karibu &&
          karibu.map(({ image, title, hierarchy }, idx) => (
            <div
              className="relative rounded-[1rem] h-[12rem] lg:h-[25rem] lg:w-[20rem] shadow-sm cursor-pointer group overflow-hidden col-span-2"
              key={idx}
            >
              <HashLink
                to={
                  title === "Our Heritage"
                    ? "/our-heritage"
                    : `/karibu-highlands/#${hierarchy}`
                }
              >
                <img
                  src={urlFor(image).url()}
                  alt="articleimage"
                  className="h-full w-full object-cover rounded-[1rem]"
                />
                <div className="absolute -bottom-20 left-0 p-3 group-hover:bottom-0 transition-all duration-500 ease-in">
                  <p className="text-xl lg:text-3xl text-white font-bold leading-8">
                    {title}
                  </p>
                  <HFilledButton
                    variant="contained"
                    disableElevation
                    sx={{
                      marginY: "1.25rem",
                      paddingX: "1.25rem",
                      width: "100%",
                      fontSize: "1.25rem",
                      borderRadius: "1rem",
                    }}
                  >
                    <HashLink
                      to={
                        title === "Our Heritage"
                          ? "/our-heritage"
                          : `/karibu-highlands/#${hierarchy}`
                      }
                    >
                      Explore
                    </HashLink>
                  </HFilledButton>
                </div>
              </HashLink>
            </div>
          ))}
      </div>
    </div>
  );
};

export default memo(Discovery);
