import hdl_logo from "../static/logos/hdl-white-logo.png";
import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import MobileNav from "./MobileNav";
import { IoIosClose } from "react-icons/io";
import { IoCallOutline, IoMailOutline } from "react-icons/io5";
import { RiMenu4Fill } from "react-icons/ri";
import {
	RiCustomerService2Line,
	RiMailSendLine,
	RiCloseFill,
} from "react-icons/ri";
import { NavContext } from "../context/navContext";
import { Fade } from "react-reveal";

const Header = () => {
	const [isScrolled, setIsScrolled] = useState(false);
	const [isCall, setIsCall] = useState(false);
	const [isEmail, setIsEmail] = useState(false);
	const { showNav, dispatch } = useContext(NavContext);

	useEffect(() => {
		showNav
			? (document.body.style.overflow = "hidden")
			: (document.body.style.overflow = "unset");

		let prevScrollpos = window.pageYOffset;
		window.onscroll = () => {
			let currentScrollPos = window.pageYOffset;

			if (prevScrollpos > currentScrollPos) {
				setIsScrolled(false);
			} else {
				setIsScrolled(true);
			}
			prevScrollpos = currentScrollPos;
		};
	}, [showNav]);

	const handleCall = () => {
		setIsCall(!isCall);
		setIsEmail(false);
	};

	const handleEmail = () => {
		setIsEmail(!isEmail);
		setIsCall(false);
	};

	const handleClose = () => {
		setIsCall(false);
		setIsEmail(false);
	};

	return (
		<div
			className={
				!isScrolled
					? "sticky top-0 left-0 w-full bg-highblu z-40 shadow-lg"
					: "w-full bg-highblu z-40 shadow-lg"
			}
		>
			<div className="max-w-8xl mx-auto w-full px-4 lg:px-10 flex items-center justify-between gap-x-8 py-2">
				<Link to="/" className="z-40">
					<img src={hdl_logo} alt="hdl_logo" className="h-[2.3rem]" />
				</Link>
				{showNav ? <MobileNav /> : <Navbar />}
				<div className="hidden lg:flex items-center gap-x-5 text-white relative">
					<RiCustomerService2Line
						size={20}
						className="cursor-pointer"
						onClick={handleCall}
					/>
					<RiMailSendLine
						size={20}
						className="cursor-pointer"
						onClick={handleEmail}
					/>
					<Fade right opposite when={isCall}>
						<div
							className={
								isCall
									? "absolute top-10 right-7 w-[20rem] px-3 pb-2 flex gap-x-3 bg-slate-100 border border-gray-200 text-black text-sm shadow-xl rounded-md"
									: "hidden"
							}
						>
							<IoCallOutline
								size={20}
								className="mt-1 text-highblu"
							/>
							<div className="w-full">
								<div className="flex justify-between items-center w-full">
									<p className="font-bold text-gray-700">
										Call us:
									</p>
									<RiCloseFill
										className="cursor-pointer hover:scale-150 transition-all duration-100 ease-linear"
										onClick={handleClose}
									/>
								</div>
								<p className="text-xs font-medium text-gray-500">
									Tel: +254 (0) 790 490 529
								</p>
							</div>
						</div>
					</Fade>
					<Fade right opposite when={isEmail}>
						<div
							className={
								isEmail
									? "absolute top-10 right-0 w-[20rem] px-3 pb-2 flex gap-x-3 bg-slate-100 border border-gray-200 text-black text-sm shadow-xl rounded-md"
									: "hidden"
							}
						>
							<IoMailOutline
								size={20}
								className="mt-1 text-highblu"
							/>
							<div className="w-full">
								<div className="flex justify-between items-center w-full">
									<p className="font-bold text-gray-700">
										Email us:
									</p>
									<RiCloseFill
										className="cursor-pointer hover:scale-150 transition-all duration-100 ease-linear"
										onClick={handleClose}
									/>
								</div>
								<p className="text-xs font-medium text-gray-500">
									customercare@highlandske.com
								</p>
							</div>
						</div>
					</Fade>
				</div>
				{showNav ? (
					<IoIosClose
						onClick={() => dispatch({ type: "HIDENAV" })}
						className="text-4xl text-highblu transition-all duration-200 ease-in z-40 cursor-pointer lg:hidden"
					/>
				) : (
					<RiMenu4Fill
						onClick={() => dispatch({ type: "SHOWNAV" })}
						className="text-3xl text-white transition-all duration-200 ease-in z-40 cursor-pointer lg:hidden"
					/>
				)}
			</div>
		</div>
	);
};

export default Header;
