import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { sanityClient } from "../lib/sanity";
import { MdArrowForward } from "react-icons/md";
import Loader from "../components/mini/Loader";
import ProductCard from "../components/mini/ProductCard";

const AllProducts = () => {
  const [products, setProducts] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "product"] | order(_createdAt desc){
				...,
            refBrand->,
			productsContent[]->
			}`
      )
      .then((data) => setProducts(data))
      .catch(console.error);
  }, []);

  if (!products) {
    return <Loader />;
  }

  return (
    <>
      <div className="max-w-5xl mx-auto">
        <h1 className="text-2xl lg:text-5xl font-inter font-black text-center text-highblu pt-4 uppercase">
          our products
        </h1>
        <div className="my-[1rem] lg:mb-[1rem] py-5">
          <div className="flex justify-between items-center px-5 xl:px-0">
            <h1 className="text-lg lg:text-3xl text-highor font-bold">
              Highlands Water
            </h1>
            <div className="">
              <div className="flex items-center gap-x-0.5 text-highblu justify-self-end">
                <Link
                  to={`/products/highlands-water`}
                  className="text-xxs lg:text-sm font-semibold"
                >
                  See more products
                </Link>
                <MdArrowForward />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-5 py-3 px-5 xl:px-0">
            {products &&
              products
                .filter((prod) => prod.refBrand.name === "Highlands Water")
                .map(({ title, slug, refBrand, sumImage }, idx) => (
                  <div key={idx}>
                    <Link
                      to={`/products/${refBrand.slug.current}/${slug.current}`}
                      className=""
                    >
                      <ProductCard
                        title={title}
                        slug={slug}
                        sumImage={sumImage}
                        refBrand={refBrand}
                      />
                    </Link>
                  </div>
                ))}
          </div>
        </div>
        <div className="lg:my-[1rem] py-5">
          <div className="flex justify-between items-center px-5 xl:px-0">
            <h1 className="text-lg lg:text-3xl text-highor font-bold">
              Highlands Cordials
            </h1>
            <div className="">
              <div className="flex items-center gap-x-0.5 text-highblu justify-self-end">
                <Link
                  to={`/products/highlands-cordials`}
                  className="text-xxs lg:text-sm font-semibold"
                >
                  See more products
                </Link>
                <MdArrowForward />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-5 py-3 px-5 xl:px-0">
            {products &&
              products
                .filter((prod) => prod.refBrand.name === "Highlands Cordials")
                .map(({ title, slug, refBrand, sumImage }, idx) => (
                  <div key={idx}>
                    <Link
                      to={`/products/${refBrand.slug.current}/${slug.current}`}
                      className=""
                    >
                      <ProductCard
                        title={title}
                        slug={slug}
                        sumImage={sumImage}
                        refBrand={refBrand}
                      />
                    </Link>
                  </div>
                ))}
          </div>
        </div>
        <div className="lg:mt-[1rem] py-5">
          <div className="flex justify-between items-center px-5 xl:px-0">
            <h1 className="text-lg lg:text-3xl text-highor font-bold">CLUB</h1>
            <div className="">
              <div className="flex items-center gap-x-0.5 text-highblu justify-self-end">
                <Link
                  to={`/products/club`}
                  className="text-xxs lg:text-sm font-semibold"
                >
                  See more products
                </Link>
                <MdArrowForward />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-5 py-3 px-5 xl:px-0">
            {products &&
              products
                .filter((prod) => prod.refBrand.name === "CLUB")
                .map(({ title, slug, refBrand, sumImage }, idx) => (
                  <div key={idx}>
                    <Link
                      to={`/products/${refBrand.slug.current}/${slug.current}`}
                      className=""
                    >
                      <ProductCard
                        title={title}
                        slug={slug}
                        sumImage={sumImage}
                        refBrand={refBrand}
                      />
                    </Link>
                  </div>
                ))}
          </div>
        </div>
        <div className="lg:mt-[1rem] py-5">
          <div className="flex justify-between items-center px-5 xl:px-0">
            <h1 className="text-lg lg:text-3xl text-highor font-bold">
              Bazuu Energy
            </h1>
            <div className="">
              <div className="flex items-center gap-x-0.5 text-highblu justify-self-end">
                <Link
                  to={`/products/bazuu-energy`}
                  className="text-xxs lg:text-sm font-semibold"
                >
                  See more products
                </Link>
                <MdArrowForward />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-5 py-3 px-5 xl:px-0">
            {products &&
              products
                .filter((prod) => prod.refBrand.name === "Bazuu Energy")
                .map(({ title, slug, refBrand, sumImage }, idx) => (
                  <div key={idx}>
                    <Link
                      to={`/products/${refBrand.slug.current}/${slug.current}`}
                      className=""
                    >
                      <ProductCard
                        title={title}
                        slug={slug}
                        sumImage={sumImage}
                        refBrand={refBrand}
                      />
                    </Link>
                  </div>
                ))}
          </div>
        </div>
        <div className="lg:mt-[1rem] py-5">
          <div className="flex justify-between items-center px-5 xl:px-0">
            <h1 className="text-lg lg:text-3xl text-highor font-bold">
              Royal CLUB
            </h1>
            <div className="">
              <div className="flex items-center gap-x-0.5 text-highblu justify-self-end">
                <Link
                  to={`/products/royal-club`}
                  className="text-xxs lg:text-sm font-semibold"
                >
                  See more products
                </Link>
                <MdArrowForward />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-5 py-3 px-5 xl:px-0">
            {products &&
              products
                .filter((prod) => prod.refBrand.name === "Royal CLUB")
                .map(({ title, slug, refBrand, sumImage }, idx) => (
                  <div key={idx}>
                    <Link
                      to={`/products/${refBrand.slug.current}/${slug.current}`}
                      className=""
                    >
                      <ProductCard
                        title={title}
                        slug={slug}
                        sumImage={sumImage}
                        refBrand={refBrand}
                      />
                    </Link>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AllProducts;
