import * as React from "react";
import { emphasize, styled } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
import HomeIcon from "@mui/icons-material/Home";
import { useLocation, Link } from "react-router-dom";
import { NavigateNextSharp } from "@mui/icons-material";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: 5,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

const CustomizedBreadcrumbs = () => {
  const location = useLocation();

  const crumbs = location.pathname.split("/").filter((crumb) => crumb !== "");

  return (
    <div role="presentation" className="py-4">
      <Breadcrumbs
        aria-label="breadcrumb"
        className="overflow-hidden"
        separator={<NavigateNextSharp fontSize="small" />}
      >
        <Link to="/">
          <StyledBreadcrumb
            className="hover:underline"
            component="a"
            href="/"
            label="Home"
            icon={<HomeIcon fontSize="small" />}
          />
        </Link>
        {crumbs.map((crumb, index) => {
          const routeTo = `/${crumbs.slice(0, index + 1).join("/")}`;
          const isLast = index === crumbs.length - 1;

          return isLast || crumb === "article" ? (
            <StyledBreadcrumb
              component="a"
              className="capitalize"
              variant="outlined"
              key={index}
              label={crumb.replaceAll("-", " ")}
            />
          ) : (
            <Link to={routeTo}>
              <StyledBreadcrumb
                component="a"
                className="capitalize hover:underline"
                href={crumb}
                key={index}
                label={crumb.replaceAll("-", " ")}
              />
            </Link>
          );
        })}
      </Breadcrumbs>
    </div>
  );
};

export default CustomizedBreadcrumbs;
