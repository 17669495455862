import React, { memo } from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";
import { PiPhoneCallLight } from "react-icons/pi"
import { Link } from "react-router-dom";

const Links = () => {
  return (
    <div>
      <div className="pb-5 md:px-5 xl:px-0">
        <div className="max-w-5xl mx-auto flex items-center justify-center gap-x-3">
          <p className="text-highblu font-bold text-lg md:text-xl xl:text-3xl">
            Get social with us
          </p>
          <div className="flex gap-x-2 text-lg">
            <a
              href="https://www.facebook.com/HighlandsDrinks"
              target="_blank"
              rel="noreferrer"
              className="hover:-translate-y-0.5 transition-all duration-150 ease-linear text-[#1877F2]"
            >
              <FaFacebookF size={28} />
            </a>
            <a
              href="https://www.instagram.com/highlandsdrinks/"
              target="_blank"
              rel="noreferrer"
              className="hover:-translate-y-0.5 transition-all duration-150 ease-linear text-[#d62976]"
            >
              <FaInstagram size={28} />
            </a>
            <a
              href="https://www.linkedin.com/company/highlands-drinks-limited/"
              target="_blank"
              rel="noreferrer"
              className="hover:-translate-y-0.5 transition-all duration-150 ease-linear text-[#0077b5]"
            >
              <FaLinkedinIn size={28} />
            </a>
          </div>
          <div>
            <Link
              to="/contactus"
              className="text-highblu text-lg xl:text-2xl font-bold border-l border-l-highblu px-2 flex items-center gap-x-2"
            >
              <span><PiPhoneCallLight size={30} /></span>
              Contact us
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Links);
