import customer from "../static/customer_bl.png";
import innovation from "../static/bright_mind.png";
import integrity from "../static/scales_bl.png";
import staff from "../static/staff.png";

const Features = () => {
  return (
    <div className="my-10 py-10 px-5 xl:px-0">
      <div className=" max-w-5xl mx-auto">
        <div className="flex flex-col items-center pb-10">
          <div className="bg-highblu rounded-full uppercase text-xs py-1 px-3 font-semibold text-white">
            <p>best company ever</p>
          </div>
          <h1 className="text-3xl xl:text-4xl text-center font-bold text-highblu">
            Refreshing Kenyans with Joy since 1954!
          </h1>
          <p className="lg:w-1/2 text-center lg:text-xl">
            We delight our customers with Great Tasting, Refreshing and
            Affordable Drinks where and when they want.
          </p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
          <div className="rounded-xl border border-gray-200 bg-slate-100 shadow-xl p-5">
            <img className="h-12 w-12" src={customer} alt="icon" />
            <div className="py-3">
              <h2 className="font-semibold text-lg pb-3">Customer Focus</h2>
              <div className="bg-highblu w-[3rem] h-[0.2rem]"></div>
            </div>
            <p className="text-sm">
              We listen and communicate with our consumers to understand and
              serve them better.
            </p>
          </div>
          <div className="rounded-xl border border-gray-200 bg-slate-100 shadow-xl p-5">
            <img className="h-12 w-12" src={innovation} alt="icon" />
            <div className="py-3">
              <h2 className="font-semibold text-lg pb-3">Innovative</h2>
              <div className="bg-highblu w-[3rem] h-[0.2rem]"></div>
            </div>
            <p className="text-sm">
              We focus on constant product and process innovation to always have
              the best taste at the best price.
            </p>
          </div>
          <div className="rounded-xl border border-gray-200 bg-slate-100 shadow-xl p-5">
            <img className="h-12 w-12" src={integrity} alt="icon" />
            <div className="py-3">
              <h2 className="font-semibold text-lg pb-3">Real Integrity</h2>
              <div className="bg-highblu w-[3rem] h-[0.2rem]"></div>
            </div>
            <p className="text-sm">
              Giving our best at all times in support of our colleagues and
              customers.
            </p>
          </div>
          <div className="rounded-xl border border-gray-200 bg-slate-100 shadow-xl p-5">
            <img className="h-12 w-12" src={staff} alt="icon" />
            <div className="py-3">
              <h2 className="font-semibold text-lg pb-3">Powered by People</h2>
              <div className="bg-highblu w-[3rem] h-[0.2rem]"></div>
            </div>
            <p className="text-sm">
              We continue to recognize that every employee has an equally
              important part to play in our success story.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
