import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Bazuu from "../components/Bazuu";
import Club from "../components/Club";
import Cordial from "../components/Cordial";
import Water from "../components/Water";
import Royal from "../components/Royal";

const Products = () => {
  const { brand } = useParams();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="">
      {brand === "club" ? (
        <Club brand={brand} />
      ) : brand === "highlands-cordials" ? (
        <Cordial brand={brand} />
      ) : brand === "bazuu-energy" ? (
        <Bazuu brand={brand} />
      ) : brand === "highlands-water" ? (
        <Water brand={brand} />
      ) : brand === "royal-club" ? (
        <Royal brand={brand} />
      ) : (
        <h1>Nada!</h1>
      )}
    </div>
  );
};

export default Products;
