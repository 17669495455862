import { Chip } from "@mui/material";
import { sanityClient } from "../lib/sanity";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const JobCategory = () => {
	const [category, setCategory] = useState(null);

	useEffect(() => {
		sanityClient
			.fetch(
				`*[_type == "jobCategory"] | order(_createdAt desc){
                name,
                slug
            }`
			)
			.then((data) => setCategory(data))
			.catch(console.error);
	}, []);

	return (
		<div className="mt-[5rem] flex items-center gap-x-3 gap-y-4 flex-wrap px-10 xl:px-0">
			<Chip
				label="View All"
				variant="filled"
				color="warning"
				size="small"
			/>
			{category &&
				category.map(({ name, slug }, idx) => (
					<Link to={`/workwithus/category/${slug.current}`} key={idx}>
						<Chip
							label={name}
							variant="outlined"
							size="small"
							className="hover:bg-highblu hover:text-white"
						/>
					</Link>
				))}
		</div>
	);
};

export default JobCategory;
