import { createContext, useReducer } from "react";
import productReducer from "./productReducer";

const INITIAL_STATE = {
	showProdModal: false,
};

export const ProductContext = createContext(INITIAL_STATE);

export const ProductContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(productReducer, INITIAL_STATE);

	return (
		<ProductContext.Provider
			value={{
				showProdModal: state.showProdModal,
				dispatch,
			}}>
			{children}
		</ProductContext.Provider>
	);
};
