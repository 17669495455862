import { sanityClient } from "../lib/sanity";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import ProductCard from "./mini/ProductCard";

const RelatedProduct = () => {
  const [related, setRelated] = useState(null);
  const { brand, slug } = useParams();

  const handleScroll = () => {
    window.scroll(0, 0);
  };

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "product" && refBrand->.slug.current == "${brand}" && slug.current != "${slug}"][0...4] | order(_createdAt desc){
                ...,
				        refBrand->,
				        productsContent[]->,
            }`
      )
      .then((data) => setRelated(data))
      .catch(console.error);
  }, [brand, slug]);

  return (
    <div className="grid grid-cols-2 lg:grid-cols-4 gap-3 lg:gap-x-5">
      {related &&
        related
          .filter((prod) => prod.slug.current !== slug)
          .map(({ title, sumImage, slug, refBrand }, idx) => (
            <Link
              to={`/products/${refBrand.slug.current}/${slug.current}`}
              onClick={handleScroll}
              key={idx}
            >
              <ProductCard
                title={title}
                slug={slug}
                sumImage={sumImage}
                refBrand={refBrand}
              />
            </Link>
          ))}
    </div>
  );
};

export default RelatedProduct;
