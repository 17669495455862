import { useState, useEffect } from "react";
import { sanityClient } from "../lib/sanity";
import { useParams } from "react-router-dom";
import { PortableText } from "@portabletext/react";
import { serializers } from "../utils/serializers";

const PStory = () => {
  const [story, setStory] = useState(null);
  const { brand } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "productBanner" && brand->.slug.current == "${brand}"][0]{
                        ...,
                        brand->,
                    }`
      )
      .then((data) => setStory(data));
  }, [brand]);

  return (
    <>
      {story && (
        <div className="bg-[url('/src/static/bg-gray.jpg')] bg-contain py-5">
          <div className="flex flex-col lg:flex lg:flex-row gap-x-10 max-w-4xl mx-auto py-5 px-5 xl:px-0">
            <div className="">
              <h1 className="font-bold text-drkblu text-xl lg:text-3xl leading-none">
                {story.title}
              </h1>
              <div className="text-sm text-drkblu font-medium py-4 font-inter">
                <PortableText value={story.story} components={serializers} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PStory;
