import { createContext, useReducer } from "react";
import newsReducer from "./newsReducer";

const INITIAL_STATE = {
	showNews: false,
};

export const NewsContext = createContext(INITIAL_STATE);

export const NewsContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(newsReducer, INITIAL_STATE);

	return (
		<NewsContext.Provider
			value={{
				showNews: state.showNews,
				dispatch,
			}}>
			{children}
		</NewsContext.Provider>
	);
};
