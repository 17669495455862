import { Route, Routes } from "react-router-dom";

// page and layout imports
import Footer from "./components/Footer";
import Header from "./components/Header";
import AllProducts from "./pages/AllProducts";
import Careers from "./pages/Careers";
import Contact from "./pages/Contact";
import FAQ from "./pages/FAQ";
import Press from "./pages/Press";
import Home from "./pages/Home";
import ProdDetails from "./pages/ProdDetails";
import CareerDets from "./pages/CareerDets";
import ProductsBase from "./pages/ProductsBase";
import DepartmentJobs from "./pages/DepartmentJobs";
import CategoryJobs from "./pages/CategoryJobs";
import KaribuHighlands from "./pages/KaribuHighlands";
import Heritage from "./pages/Heritage";
import NewsDetails from "./pages/NewsDetails";
import Sig from "./pages/Sig";
import ArticleTags from "./pages/ArticleTags";
import AllArticles from "./pages/AllArticles";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";
import { inject } from "@vercel/analytics";

function App() {
  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<AllProducts />} path="/products" />
        <Route element={<KaribuHighlands />} path="/karibu-highlands" />
        <Route element={<Heritage />} path="/our-heritage" />
        <Route element={<Press />} path="/news-events" />
        <Route element={<ArticleTags />} path="/news-events/:tag" />
        <Route element={<AllArticles />} path="/news-events/all" />
        <Route element={<NewsDetails />} path="/news-events/article/:slug" />
        <Route element={<Careers />} path="/workwithus" />
        <Route element={<CareerDets />} path="/workwithus/:slug" />
        <Route
          element={<DepartmentJobs />}
          path="/workwithus/department/:slug"
        />
        <Route element={<CategoryJobs />} path="/workwithus/category/:slug" />
        <Route element={<FAQ />} path="/FAQs" />
        <Route element={<Contact />} path="/contactus" />
        <Route element={<ProductsBase />} path="/products/:brand" />
        <Route element={<ProdDetails />} path="/products/:brand/:slug" />
        <Route element={<Sig />} path="/high/sig" />
      </Routes>
      <Footer />
      <SpeedInsights />
      <Analytics />
    </div>
  );
}
inject();

export default App;
