import { Link, useParams } from "react-router-dom";
import { RiArrowRightUpLine } from "react-icons/ri";
import { PortableText, sanityClient, urlFor } from "../lib/sanity";
import { useState, useEffect } from "react";
import departmentImg from "../static/department2.jpg";
import Loader from "../components/mini/Loader";
import { Avatar, Chip } from "@mui/material";
import NoJob from "../components/mini/NoJob";

const CategoryJobs = () => {
	const [jobs, setJobs] = useState(null);
	const { slug } = useParams();
	const nowDate = new Date();
	const today = nowDate.toISOString();

	useEffect(() => {
		sanityClient
			.fetch(
				`*[_type == "job" && count((category[]->slug.current)[@ in ["${slug}"]]) > 0]{
				title,
				slug,
        category[]->,
				department->,
				description,
        deadline,
				fullDescription,
				type[] ->
			}`
			)
			.then((data) => setJobs(data))
			.catch(console.error);
		window.scroll(0, 0);
	}, [slug]);

	if (!jobs) {
		return <Loader />;
	}

	return (
		<div>
			<div className="h-[30rem] w-full relative">
				<div className="absolute top-0 left-0 w-full h-[30rem] bg-black bg-opacity-20"></div>
				<img
					src={departmentImg}
					alt="department_img"
					className="h-[30rem] object-cover w-full"
				/>
				<div className="absolute top-0 left-0 w-full h-[30rem] bg-transparent grid place-items-center">
					<p className="font-inter font-black text-white text-3xl lg:text-7xl z-10 capitalize">
						{slug.replaceAll("-", " ")}
					</p>
				</div>
			</div>
			<div className="px-5 md:px-10 xl:px-0 max-w-5xl mx-auto mb-[3rem]">
				<h1 className="font-bold text-xl lg:text-3xl text-highblu py-5 capitalize relative after:block after:bg-highblu after:absolute after:bottom-2 after:left-0 after:h-2 after:w-1/6">{`Positions in ${slug.replaceAll(
					"-",
					" "
				)}`}</h1>
				{jobs &&
					jobs
						.filter((job) => job.deadline >= today)
						.map(({ title, slug, description, type }, idx) => (
							<Link to={`/workwithus/${slug.current}`}>
								<div
									className="space-y-3 border-t border-t-gray-400 px-2 py-[2rem] max-w-5xl mx-auto mt-4 hover:bg-gray-200 transition-colors duration-300 ease-in-out"
									key={idx}
								>
									<div className="flex items-center justify-between">
										<h1 className="font-bold font-inter text-lg lg:text-3xl capitalize">
											{title}
										</h1>
										<Link
											to={`/workwithus/${slug.current}`}
											className="flex items-center font-bold font-inter text-sm lg:text-2xl"
										>
											Apply <RiArrowRightUpLine />
										</Link>
									</div>
									<div className="font-medium text-sm lg:text-base">
										<PortableText value={description} />
									</div>
									<div className="flex items-center gap-x-3">
										{type &&
											type.map(({ name, icon }, idx) => (
												<Chip
													avatar={
														<Avatar
															alt="icon"
															src={urlFor(
																icon
															).url()}
														/>
													}
													label={name}
													variant="outlined"
													size="small"
													key={idx}
												/>
											))}
									</div>
								</div>
							</Link>
						))}

				{(jobs?.filter((job) => job.deadline >= today).length === 0 ||
					jobs?.length === 0) && <NoJob />}
			</div>
		</div>
	);
};

export default CategoryJobs;
