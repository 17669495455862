const eventsReducer = (state, action) => {
	switch (action.type) {
		case "SHOW": {
			return {
				showEvents: true,
			};
		}
		case "HIDE": {
			return {
				showEvents: false,
			};
		}
		default: {
			return {
				showEvents: !state.showCase,
			};
		}
	}
};

export default eventsReducer;
