import { useReducer } from "react";
import { createContext } from "react";

const modalReducer = (state, action) => {
  switch (action.type) {
    case "MODAL_SHOW": {
      return {
        modalShow: action.payload,
      };
    }
    case "MODAL_HIDE": {
      return {
        modalShow: false,
      };
    }
    default: {
      return {
        modalShow: state,
      };
    }
  }
};

const INITIAL_STATE = {
  modalShow: "",
};

export const ModalContext = createContext({ state: INITIAL_STATE });

export const ModalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(modalReducer, INITIAL_STATE);

  return (
    <ModalContext.Provider value={{ state, dispatch }}>
      {children}
    </ModalContext.Provider>
  );
};
