import { useParams } from "react-router-dom";
import { useLoadScript } from "@react-google-maps/api";
import Map from "../components/Map";
import { useState, useEffect } from "react";
import { sanityClient, urlFor } from "../lib/sanity";
import { PortableText } from "@portabletext/react";
import { BiDownload } from "react-icons/bi";
import Loader from "../components/mini/Loader";
import { HFilledButton } from "../components/mini/HButton";

const CareerDets = () => {
	const { slug } = useParams();
	const [details, setDetails] = useState(null);

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
	});

	useEffect(() => {
		sanityClient
			.fetch(
				`*[_type == "job" && slug.current == "${slug}"]{
					title,
					mainImage,
					pdf,
					"PDFurl": pdf.asset->url,
					location->,
					type[] ->,
					skills[] ->,
					description,
			}`
			)
			.then((data) => setDetails(data))
			.catch(console.error);
		window.scroll(0, 0);
	}, [slug]);

	if (!details) {
		return <Loader />;
	}

	if (!isLoaded) return <div>...</div>;

	return (
		<>
			{details &&
				details.map(
					(
						{
							title,
							mainImage,
							location,
							PDFurl,
							description,
							pdf,
						},
						idx
					) => (
						<div className="" key={idx}>
							<div className="bg-highblu h-[14rem] lg:h-[20rem] w-full relative">
								<div className="absolute top-0 left-0 bg-transparent w-full h-full grid items-center">
									<div className="max-w-5xl w-full mx-auto text-white space-y-5 px-10 xl:px-0">
										<h1 className="font-black font-inter text-xl lg:text-5xl capitalize">
											{title}
										</h1>
										<div className="font-semibold text-sm lg:text-xl text-3/6">
											<PortableText value={description} />
										</div>
										<div>
											{/* <a
                        href="mailto:recruitment@highlandske.com"
                        className="bg-orange-500 py-2 text-xs lg:text-sm font-semibold px-3 uppercase border border-orange-500 rounded shadow-lg"
                      >
                        apply for this job
                      </a> */}
											<HFilledButton
												variant="contained"
												sx={{
													backgroundColor: "#FFFFFF",
													color: "#0055A5",
												}}
												href="mailto:recruitment@highlandske.com"
											>
												APPLY FOR THIS JOB
											</HFilledButton>
										</div>
									</div>
								</div>
							</div>
							<div className="max-w-5xl mx-auto flex flex-col lg:flex-row p-5 xl:px-0 justify-between">
								<div className="lg:w-3/4">
									<div className="mb-[2rem]">
										<h1 className="font-inter font-black text-highblu text-xl lg:text-3xl py-4">
											Job Description
										</h1>
										<img
											src={urlFor(mainImage).url()}
											alt="job_description"
											className="w-full h-full object-cover"
										/>
									</div>
								</div>
								<div className="h-[15rem] w-[10rem] lg:mt-[5rem] hidden lg:flex lg:flex-col gap-y-2">
									<p className="text-sm font-medium">
										Download Job
									</p>
									<a
										href={PDFurl}
										download={pdf.description.pdf}
										className="flex items-center gap-x-2"
										target="_blank"
										rel="noreferrer"
										title="Click to download job advert"
									>
										<img
											src={urlFor(mainImage).url()}
											alt="job_image"
											className="object-cover h-[15rem]"
										/>
									</a>
								</div>
								<button className="bg-highblu p-2 text-sm font-bold flex justify-center lg:hidden text-white rounded">
									<a
										href={PDFurl}
										download={pdf.description.pdf}
										className="flex items-center gap-x-2"
									>
										Download <BiDownload />
									</a>
								</button>
							</div>
							<div className="mb-5 max-w-5xl mx-auto">
								<div className="px-5 xl:px-0">
									<h1 className="capitalize text-gray-600 font-inter font-black text-xl">
										location
									</h1>
									<p className="font-semibold text-gray-500 text-sm">
										{location.name}
									</p>
								</div>
								<Map />
							</div>
						</div>
					)
				)}
		</>
	);
};

export default CareerDets;
