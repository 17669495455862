const brandReducer = (state, action) => {
	switch (action.type) {
		case "SHOWBRANDNAV": {
			return {
				showBrandMenu: true,
			};
		}
		case "HIDEBRANDNAV": {
			return {
				showBrandMenu: false,
			};
		}
		default: {
			return {
				showBrandMenu: !state.showBrandMenu,
			};
		}
	}
};

export default brandReducer;
