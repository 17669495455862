import { useEffect } from "react";
import { useState } from "react";
import { sanityClient, urlFor } from "../lib/sanity";
import ImageModal from "./mini/ImageModal";

const CareerFun = () => {
  const [funImages, setFunImages] = useState(null);
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "careerFun"] | order(_createdAt desc) {
				title,
				slug,
				funImage,
			}`
      )
      .then((data) => setFunImages(data))
      .catch(console.error);
  }, []);

  return (
    <>
      {funImages && (
        <div className="columns-2 md:columns-3 lg:columns-4">
          {funImages[0].funImage &&
            funImages[0].funImage.map(({ fImage }, idx) => (
              <div class="mb-4 aspect-w-1 aspect-h-1">
                <img
                  className="h-full w-full rounded-lg object-cover cursor-pointer"
                  src={urlFor(fImage).url()}
                  key={idx}
                  alt=""
                  onClick={handleOpen}
                />
              </div>
            ))}
        </div>
      )}
      <ImageModal open={open} handleClose={handleClose} funImages={funImages} />
    </>
  );
};

export default CareerFun;
