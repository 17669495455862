import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { NavContextProvider } from "./context/navContext";
import { BrandContextProvider } from "./context/brandContext";
import { ProductContextProvider } from "./context/productContext";
import { FaqContextProvider } from "./context/faqContext";
import { BrowserRouter } from "react-router-dom";
import { DiscoveryContextProvider } from "./context/discoveryContext";
import { EventsContextProvider } from "./context/eventsContext";
import { NewsContextProvider } from "./context/newsContext";
import { ModalContextProvider } from "./context/modalContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <NavContextProvider>
      <BrandContextProvider>
        <ProductContextProvider>
          <FaqContextProvider>
            <DiscoveryContextProvider>
              <EventsContextProvider>
                <NewsContextProvider>
                  <ModalContextProvider>
                    <App />
                  </ModalContextProvider>
                </NewsContextProvider>
              </EventsContextProvider>
            </DiscoveryContextProvider>
          </FaqContextProvider>
        </ProductContextProvider>
      </BrandContextProvider>
    </NavContextProvider>
  </BrowserRouter>
);
