import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Products from "./Products";
import { sanityClient, urlFor } from "../lib/sanity";
import PStory from "../components/PStory";

const Brand = () => {
  const { brand } = useParams();
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "brandBanner" && brand->.slug.current == "${brand}"][0]{
				title,
				brand->,
				slug,
				useVideo,
				bannerImage,
				brandLogo
			}`
      )
      .then((data) => setBanner(data))
      .catch(console.error);
    window.scroll(0, 0);
  }, [brand]);

  return (
    <div className="relative">
      {banner && (
        <div className="">
          <img
            src={urlFor(banner.bannerImage).url()}
            alt="brand_image"
            className="lg:h-[22.1rem] 2xl:h-[31rem] lg:w-full object-contain lg:object-cover"
          />
          <PStory />
        </div>
      )}
      <Products />
    </div>
  );
};

export default Brand;
