import press from "../static/news-Slide.jpg";
import { useEffect, useState } from "react";
import { sanityClient, urlFor } from "../lib/sanity";
import { Link } from "react-router-dom";
import quill from "../static/quill_pen.svg";
import Loader from "../components/mini/Loader";
import moment from "moment/moment";
import { PortableText } from "@portabletext/react";
import { Button } from "@mui/material";

const Press = () => {
  const [letter, setLetter] = useState(null);
  const [firstPost, setFirstPost] = useState(null);
  const [sidePosts, setSidePosts] = useState(null);

  useEffect(() => {
    // fetch more articles
    sanityClient
      .fetch(
        `*[_type == "news"] | order(_updatedAt desc)[4...25]{
					_updatedAt,
				title,
				slug,
				mainImage,
				tag->,
				content
			}`
      )
      .then((data) => setLetter(data))
      .catch(console.error);

    //   fetch head article
    sanityClient
      .fetch(
        `*[_type == "news"] | order(_updatedAt desc)[0]{
					_updatedAt,
          _createdAt,
				title,
				slug,
				mainImage,
				tag->,
				content
			}`
      )
      .then((data) => setFirstPost(data))
      .catch(console.error);

    //   fetch sidebar articles
    sanityClient
      .fetch(
        `*[_type == "news"] | order(_updatedAt desc)[1..3]{
					_updatedAt,
          __createdAt,
				title,
				slug,
				mainImage,
				tag->,
				content
			}`
      )
      .then((data) => setSidePosts(data))
      .catch(console.error);
  }, []);

  if (!letter) {
    return <Loader />;
  }

  return (
    <div className="mb-5">
      <div className="h-[20rem] lg:h-[30rem] relative">
        <img
          src={press}
          alt="press_img"
          className="h-[20rem] sm:h-[30rem] w-full object-cover"
          loading="eager"
        />
        <div className="absolute top-0 left-0 w-full h-[20rem] sm:h-[30rem] bg-transparent grid place-items-center">
          <p className="uppercase font-black text-2xl lg:text-4xl xl:text-6xl 2xl:text-8xl text-center text-white mb-5 z-10 relative after:block after:bg-white after:absolute after:-bottom-3 after:left-9 lg:after:left-[3rem] xl:after:left-[5.5rem] 2xl:after:left-[13.5rem] 2xl:after:w-1/2 after:h-2 after:w-2/3">
            News & Events
          </p>
        </div>
      </div>
      <div className="max-w-5xl mx-auto px-5 md:px-10 xl:px-0">
        <div className="flex">
          <h1 className="font-inter font-black text-4xl lg:text-6xl py-5 text-highblu">
            News & Events
          </h1>
        </div>
        <div className="flex flex-col lg:flex-row gap-y-10 lg:gap-x-10 mb-10">
          {firstPost && (
            <Link
              to={`article/${firstPost.slug.current}`}
              className="lg:w-[80rem] lg:h-[25rem]"
            >
              <div className="flex flex-col sm:flex-row bg-white shadow-md rounded-lg">
                <img
                  src={urlFor(firstPost.mainImage).url()}
                  alt="article_img"
                  className="md:w-[22rem] md:h-[26rem] object-cover rounded-t-lg md:rounded-tr-none md:rounded-l-lg"
                />
                <div className="flex flex-col justify-between px-5 pb-7">
                  <div className="pt-3">
                    <Link to={`/news-events/${firstPost.tag.slug.current}`}>
                      <p className="text-xs text-highor font-bold">
                        {firstPost.tag.name}
                      </p>
                    </Link>
                    <p className="font-bold text-xl line-clamp-4">
                      {firstPost.title}
                    </p>
                    <p className="text-sm text-gray-700 pt-4 line-clamp-8">
                      <PortableText value={firstPost.content} />
                    </p>
                  </div>
                  <div className="flex lg:flex-col xl:flex-row xl:items-center gap-x-3 text-xs mt-2 xl:mt-0 text-gray-400">
                    <p>{moment(firstPost._updatedAt).format("D MMM YYYY")}</p>
                    <div className="flex items-center gap-x-1">
                      <img src={quill} alt="author" className="w-4 h-4" />
                      <p className="font-medium">Admin</p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          )}
          <div className="flex flex-col divide-y divide-y-gray-400">
            {sidePosts &&
              sidePosts.map(({ mainImage, title, slug, tag }, idx) => (
                <Link
                  to={`article/${slug.current}`}
                  className="first:pt-0 last:pb-0 py-5 group"
                  key={idx}
                >
                  <div className="flex gap-x-3 h-[7rem]">
                    <img
                      src={urlFor(mainImage).url()}
                      alt="article_img"
                      className="w-[8rem] lg:min-w-[8rem] object-cover rounded-lg"
                    />
                    <div className="flex flex-col justify-between">
                      <p className="text-sm font-semibold text-gray-600 line-clamp-4 group-hover:text-highblu">
                        {title}
                      </p>
                      <Link to={`/news-events/${tag.slug.current}`}>
                        <p className="text-xs text-highor font-bold">
                          {tag.name}
                        </p>
                      </Link>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </div>

        <h1 className="text-3xl text-highblu font-bold mb-5 border-y">
          More Articles
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 auto-rows-max gap-5 lg:gap-10 mb-[3rem]">
          {letter &&
            letter.map(({ title, mainImage, tag, _updatedAt, slug }, idx) => (
              <Link to={`article/${slug.current}`} key={idx}>
                <div className="">
                  <div className="h-[13rem] w-full">
                    <img
                      src={urlFor(mainImage).url()}
                      alt="newsletter_img"
                      className="object-cover h-full w-full lg:max-w-full rounded-lg"
                    />
                  </div>
                  <div className="">
                    <Link to={`/news-events/${tag.slug.current}`}>
                      <p className="text-xs text-highor font-bold py-1">
                        {tag.name}
                      </p>
                    </Link>
                    <h1 className="font-bold text-sm text-gray-700 line-clamp-2">
                      {title}
                    </h1>
                  </div>
                  <div className="flex items-center gap-x-3 text-xs text-gray-400 py-2">
                    <p>{moment(_updatedAt).format("D MMM YYYY")}</p>
                    <div className="flex items-center gap-x-1">
                      <img src={quill} alt="author" className="w-4 h-4" />
                      <p className="font-medium">Admin</p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
        </div>
        <div
          className={
            letter.length <= 21 ? "hidden" : "flex justify-center w-full"
          }
        >
          <Button variant="outlined" href="/news-events/all">
            More Articles
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Press;
