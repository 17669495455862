import { createContext, useReducer } from "react";
import navReducer from "./navReducer";

const INITIAL_STATE = {
	showNav: false,
};

export const NavContext = createContext(INITIAL_STATE);

export const NavContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(navReducer, INITIAL_STATE);

	return (
		<NavContext.Provider
			value={{
				showNav: state.showNav,
				dispatch,
			}}>
			{children}
		</NavContext.Provider>
	);
};
