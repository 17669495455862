import React, { useEffect, useState } from 'react'
import { sanityClient, urlFor } from '../lib/sanity';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import quill from "../static/quill_pen.svg";
import Loader from '../components/mini/Loader';
import NoArticle from '../components/mini/NoArticle';
import CustomizedBreadcrumbs from '../components/mini/BreadCrumbs';

const ArticleTags = () => {
    const [articleTags, setArticleTags] = useState(null)
    const { tag } = useParams()

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "news" && tag->.slug.current == "${tag}"]{
					_updatedAt,
					_createdAt,
				title,
				slug,
				mainImage,
				articleImages,
				tag->,
				content
			}`
            )
            .then((data) => setArticleTags(data))
            .catch(console.error);
    }, [tag]);

    if (!articleTags) {
        return <Loader />;
    }

    return (
        <div className='w-full'>
            <div className='max-w-5xl mx-auto px-5 md:px-10 xl:px-0'>
                <CustomizedBreadcrumbs />
                <div>
                    <h1 className='pb-5 text-3xl md:text-4xl text-highblu font-bold capitalize'>{tag.replaceAll("-", " ")}</h1>
                </div>
                <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 auto-rows-max gap-5 lg:gap-10 mb-[3rem]">
                    {articleTags &&
                        articleTags.map(({ title, mainImage, tag, _updateAt, slug }, idx) => (
                            <Link to={`/news-events/article/${slug.current}`} key={idx}>
                                <div className="">
                                    <div className="h-[13rem] w-full">
                                        <img
                                            src={urlFor(mainImage).url()}
                                            alt="newsletter_img"
                                            className="object-cover h-full w-full lg:max-w-full rounded-lg"
                                        />
                                    </div>
                                    <div className="">
                                        <p className="text-xs text-highor font-bold py-1">{tag.name}</p>
                                        <h1 className="font-bold text-sm text-gray-700 line-clamp-2">
                                            {title}
                                        </h1>
                                    </div>
                                    <div className="flex items-center gap-x-3 text-xs text-gray-400 py-2">
                                        <p>{moment(_updateAt).format("D MMM YYYY")}</p>
                                        <div className="flex items-center gap-x-1">
                                            <img src={quill} alt="author" className="w-4 h-4" />
                                            <p className="font-medium">Admin</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))}
                </div>
                {articleTags.length === 0 && <NoArticle />}
            </div>
        </div>
    )
}

export default ArticleTags