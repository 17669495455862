import React, { memo } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { sanityClient, urlFor } from "../lib/sanity";
import { HFilledButton } from "./mini/HButton";

const Fcta = () => {
  const [news, setNews] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "discoverNews"][0...3] | order(hierarchy asc){
                title,
                slug,
                image,
				refArticle->
            }`
      )
      .then((data) => setNews(data))
      .catch(console.error);
  }, []);

  return (
    <div>
      <div className="py-10">
        <div className="max-w-5xl mx-auto">
          <h1 className="text-highblu text-2xl xl:text-4xl font-semibold text-center">
            There is still a lot to discover!
          </h1>
          <div className="grid grid-cols-2 gap-3 lg:flex items-center justify-center gap-x-5 py-5 px-5 xl:px-0">
            {news &&
              news.map(({ title, refArticle, image }, idx) => (
                <div
                  className="relative rounded-[1rem] h-[12rem] lg:h-[25rem] lg:w-[16rem] shadow-sm cursor-pointer group overflow-hidden col-span-2"
                  key={idx}
                >
                  <Link to={`/news-events/article/${refArticle.slug.current}`}>
                    <img
                      src={urlFor(image).url()}
                      alt="articleimage"
                      className="h-full w-full object-cover rounded-[1rem] z-10"
                    />
                    <div className="absolute -bottom-20 left-0 p-3 group-hover:bottom-0 transition-all duration-500 ease-in">
                      <p className="text-xl lg:text-3xl text-white font-bold leading-8">
                        {title}
                      </p>
                      <HFilledButton
                        variant="contained"
                        disableElevation
                        sx={{
                          marginY: "1.25rem",
                          paddingX: "1.25rem",
                          width: "100%",
                          fontSize: "1.25rem",
                          borderRadius: "1rem",
                        }}
                        href={`/news-events/article/${refArticle.slug.current}`}
                      >
                        Learn More
                      </HFilledButton>
                    </div>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Fcta);
