import { useContext, useEffect, useRef, memo } from "react";
import { Link } from "react-router-dom";
import { NavContext } from "../context/navContext";

const MobileNav = () => {
  const { showNav, dispatch } = useContext(NavContext);
  const overRef = useRef();

  const handleMenu = () => {
    dispatch({ type: "HIDE" });
  };

  const handleHideOverlay = (e) => {
    if (e.target.outerHTML !== overRef.current.outerHTML) {
      return;
    }
    dispatch({ type: "HIDE" });
  };

  useEffect(() => {
    showNav
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [showNav]);

  return (
    <div
      className={
        showNav
          ? "fixed top-0 left-0 grid w-screen h-screen bg-white bg-opacity-20 z-40"
          : "hidden"
      }
      onClick={handleHideOverlay}
      ref={overRef}
    >
      <nav
        className={
          showNav
            ? "bg-white text-highblu h-min shadow-lg font-inter"
            : "hidden"
        }
      >
        <div className="w-[25rem] h-full">
          <div className="flex flex-col items-start p-10 text-4xl font-bold gap-y-1 relative">
            <Link
              to="/"
              onClick={handleMenu}
              className="hover:text-highblu hover:-translate-x-3 transition-all duration-300 ease-in-out"
            >
              Home
            </Link>
            <Link
              to="/products"
              onClick={handleMenu}
              className="flex items-center gap-x-1 lowercase hover:text-highblu hover:-translate-x-3 transition-all duration-300 ease-in-out"
            >
              Products
            </Link>
            <Link
              to="/karibu-highlands"
              onClick={handleMenu}
              className="lowercase hover:text-highblu hover:-translate-x-3 transition-all duration-300 ease-in-out"
            >
              About Us
            </Link>
            <Link
              to="/our-heritage"
              onClick={handleMenu}
              className="lowercase hover:text-highblu hover:-translate-x-3 transition-all duration-300 ease-in-out"
            >
              Our Heritage
            </Link>
            <Link
              to="news-events"
              onClick={handleMenu}
              className="lowercase hover:text-highblu hover:-translate-x-3 transition-all duration-300 ease-in-out"
            >
              News & Events
            </Link>
            <Link
              to="/workwithus"
              onClick={handleMenu}
              className="lowercase hover:text-highblu hover:-translate-x-3 transition-all duration-300 ease-in-out"
            >
              Careers
            </Link>
            <Link
              to="/contactus"
              onClick={handleMenu}
              className="lowercase hover:text-highblu hover:-translate-x-3 transition-all duration-300 ease-in-out"
            >
              Contact Us
            </Link>
            <Link
              to="/FAQs"
              onClick={handleMenu}
              className="uppercase hover:text-highblu	 hover:-translate-x-3 transition-all duration-300 ease-in-out"
            >
              FAQ
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default memo(MobileNav);
