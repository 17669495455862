const discoveryReducer = (state, action) => {
	switch (action.type) {
		case "DISCOVERY": {
			return {
				showDiscovery: true,
				showEvents: false,
				showNews: false,
			};
		}
		case "EVENTS": {
			return {
				showEvents: true,
				showDiscovery: false,
				showNews: false,
			};
		}
		case "NEWS": {
			return {
				showNews: true,
				showDiscovery: false,
				showEvents: false,
			};
		}
		default: {
			return {
				showDiscovery: !state.showDiscovery,
				showNews: !state.showNews,
				showEvents: !state.showEvents,
			};
		}
	}
};

export default discoveryReducer;
