import { useState } from "react";
import petco from "../static/PETCO.jpg";
import { useEffect } from "react";
import { sanityClient, urlFor } from "../lib/sanity";
import Carousel from "react-multi-carousel";

const Sustainability = () => {
  const [effluentImages, setEffluentImages] = useState(null);
  const [qaImages, setQAImages] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "masonry"] {
				title,
				masonryImage,
			}`
      )
      .then((data) => {
        setEffluentImages(data[1]);
        setQAImages(data[0]);
      })
      .catch(console.error);
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 768, min: 640 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <div className="mb-5">
      <div className="py-5">
        <div className="pb-5 max-w-5xl mx-auto px-5 xl:px-0 divide-x-8 divide-highblu">
          <h1 className="font-bold text-xl sm:text-2xl lg:text-3xl">
            It's our responsibility to respect and protect water resources.
          </h1>
          <p className="text-sm sm:text-lg pl-5">
            Water is not only the first ingredient in most of our beverages but
            it's also central to the long-term success of our business and the
            health of our communities. Our water strategy focuses on sustainable
            water security through local water replenishment, advocacy for smart
            water policies, and responsible water use throughout our operations
            and communities.
          </p>
        </div>
      </div>
      <div
        className="text-white bg-[url('/src/static/bg.jpg')] bg-contain"
        id="1"
      >
        <div className="flex flex-col lg:flex-row gap-x-10 py-5 max-w-5xl mx-auto px-5 xl:px-0">
          {effluentImages && (
            <Carousel
              arrows={false}
              autoPlay
              autoPlaySpeed={9000}
              infinite
              pauseOnHover
              slidesToSlide={1}
              swipeable
              responsive={responsive}
              showDots
              className="lg:w-[100rem] lg:h-[30rem] rounded-lg"
            >
              {effluentImages.masonryImage.map(({ image }, idx) => (
                <img
                  src={urlFor(image).url()}
                  alt=""
                  key={idx}
                  className="object-cover lg:w-full lg:h-[30rem]"
                />
              ))}
            </Carousel>
          )}
          <div className="">
            <h1 className="font-bold text-3xl">
              Effluent Water Treatment Plants
            </h1>
            <p className="font-medium lg:font-normal text-sm sm:text-lg">
              The capacity of a Wastewater Treatment Plant (WWTP) is
              proportional to the flow rate; however, the energy consumption as
              well as the number of biological oxidation ponds and sludge
              generation are mostly determined by the organic load (COD).
              <br />
              <div className="h-[1rem]"></div> Building an effluent treatment
              plant (ETP) for beverage processing businesses is the simplest way
              to avoid water contamination.
              <br />
              <div className="h-[1rem]"></div>Its main purpose is to develop a
              product that may be safely discharged into a watercourse or sewer
              while remaining below the recommended discharge limits. After
              treatment, these effluents can be reused to reduce waste.
            </p>
          </div>
        </div>
      </div>
      <div className="py-5">
        <div className="max-w-5xl mx-auto px-5 xl:px-0 divide-x-8 divide-highblu">
          <h1 className="font-bold text-xl sm:text-2xl lg:text-3xl">
            At Highlands Drinks, we thrive at Investing in our tomorrow.
          </h1>
          <p className="whitespace-pre-line text-sm sm:text-base pl-5">
            Global challenges like plastic waste and pollution are far too great
            for any single government, company, or industry to solve
            individually.
          </p>
        </div>
      </div>
      <div
        className="flex flex-col lg:flex-row gap-y-5 lg:gap-x-10 py-5 max-w-5xl mx-auto px-5 xl:px-0 text-highblu"
        id="2"
      >
        <div className="flex-1">
          <h1 className="font-bold text-3xl">PETCO Partnership</h1>
          <p className="font-medium lg:font-normal text-sm sm:text-lg">
            To achieve a World Without Waste, Highlands® is partnering with
            PETCO, a trading name of Kenya PET Recycling Company, a company
            incorporated in 2018 to represent the Kenyan PET plastic
            industry&apos;s joint effort to self-regulate post-consumer
            polyethylene terephthalate (PET) recycling. <br />
            <div className="h-[1rem]"></div>
            To achieve this everyone involved, from the raw material producers,
            the converters, brand owners, retailers, consumers and recyclers are
            playing their part in the solution, with PETCO fulfilling the PET
            industry&apos;s role of Extended Producer Responsibility (EPR).
            <br />
            <div className="h-[1rem]"></div> PETCO is financed by a voluntary
            EPR fee paid by converters and bottlers on PET resin purchased.
            PETCO also receives grants from brand owners and retailers. Support
            for PET recycling efforts ensures an ongoing monetary value for
            post-consumer PET. This sustains collection interest and reduces the
            volume of post-consumer PET in the waste stream.
          </p>
        </div>
        <img
          src={petco}
          alt="petco"
          className="lg:w-[25rem] lg:h-[33rem] object-cover rounded-lg"
        />
      </div>
      <div
        className="text-white bg-[url('/src/static/bg.jpg')] bg-contain"
        id="3"
      >
        <div className="flex flex-col-reverse lg:flex-row gap-y-5 lg:gap-x-10 py-5 max-w-5xl mx-auto px-5 xl:px-0">
          {qaImages && (
            <Carousel
              arrows={false}
              autoPlay
              autoPlaySpeed={9000}
              infinite
              pauseOnHover
              slidesToSlide={1}
              swipeable
              responsive={responsive}
              showDots
              className="lg:w-[100rem] lg:h-[30rem] rounded-lg"
            >
              {qaImages.masonryImage.map(({ image }, idx) => (
                <img
                  src={urlFor(image).url()}
                  alt=""
                  key={idx}
                  className="lg:h-[30rem] object-cover lg:w-full"
                />
              ))}
            </Carousel>
          )}

          <div className="">
            <h1 className="font-bold text-xl sm:text-3xl">
              Quality: The backbone of our Business.
            </h1>
            <p className="font-medium lg:font-normal text-sm sm:text-lg">
              We are evolving our business strategy to become a total beverage
              company by giving people more of the drinks they want.
              <br />
              <div className="h-[1rem]"></div> This strategy includes changing
              recipes to reduce added sugar, promoting low and no-calorie
              beverage options and making smaller packages more available to
              enable portion control.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sustainability;
