import React, { useEffect, useState } from "react";
import bg from "../static/Background.jpg";
import tangu_1954 from "../static/logos/tangu.png";
import Awards from "../components/Awards";
import Fcta from "../components/Fcta";
import Links from "../components/Links";
import Sustainability from "./Sustainability";
import sustainability from "../static/Sustainability.jpg";
import Features from "../components/Features";
import Carousel from "react-multi-carousel";
import CustomDot from "../utils/CustomDot";
import { sanityClient, urlFor } from "../lib/sanity";

const KaribuHighlands = () => {
	const [karibu, setKaribu] = useState(null);

	useEffect(() => {
		sanityClient
			.fetch(
				`*[_type == "karibuHighlands"] | order(_createdAt desc) {
				title,
				slug,
				karibuImage,
			}`
			)
			.then((data) => setKaribu(data[0]))
			.catch(console.error);
	}, []);

	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 1024 },
			items: 1,
			slidesToSlide: 1,
		},
		desktop: {
			breakpoint: { max: 1024, min: 768 },
			items: 1,
			slidesToSlide: 1,
		},
		tablet: {
			breakpoint: { max: 768, min: 640 },
			items: 1,
			slidesToSlide: 1,
		},
		mobile: {
			breakpoint: { max: 640, min: 0 },
			items: 1,
			slidesToSlide: 1,
		},
	};

	return (
		<div>
			<div className="h-[20rem] lg:h-[30rem] w-full relative">
				{karibu &&
					karibu.karibuImage
						.slice(0, 1)
						.map(({ kImage }, idx) => (
							<img
								src={urlFor(kImage).url()}
								alt="banner_img"
								className="h-[20rem] lg:h-[30rem] w-full object-contain lg:object-cover"
								key={idx}
								loading="eager"
							/>
						))}
				<div className="absolute top-0 left-0 w-full h-[20rem] lg:h-[30rem] bg-transparent grid place-items-center px-5 xl:px-0">
					<div className="text-white">
						<p className="uppercase font-black text-2xl lg:text-4xl xl:text-6xl 2xl:text-8xl text-center mb-5 z-10 relative after:block after:bg-white after:absolute after:-bottom-3 after:left-12 lg:after:left-[4rem] xl:after:left-[6.5rem] 2xl:after:left-[15.5rem] 2xl:after:w-1/2 after:h-2 after:w-2/3">
							Karibu Highlands
						</p>
						<p className="text-sm lg:text-xl text-center uppercase font-medium">
							Our Promise: Great Taste & Great Value
						</p>
					</div>
				</div>
			</div>
			<div className="bg-[url('/src/static/bg.jpg')] bg-contain lg:pb-[5rem]">
				<div className="max-w-5xl mx-auto flex flex-col gap-y-5 lg:flex-row justify-between py-10 px-5 xl:px-0">
					<div className="lg:w-1/2">
						<p className="uppercase font-bold text-gray-900 text-sm opacity-75 leading-none">
							karibu highlands
						</p>
						<h1 className="text-white font-bold text-3xl leading-none py-3">
							Refreshing Kenyans for Half a Century!
						</h1>
						<p className="text-white text-sm lg:text-lg">
							Highlands is a Kenyan company manufacurting and
							marketing non-alcoholic beverages, with a history
							spanning 69 years.
							<br />
							<div className="h-[1rem]"></div> A Pioneer of the
							East African beverages industry, Highlands has been
							transformed from a small family factory to a
							category leader with an "own-brand" portfolio that
							spans the major beverage categories and consumer
							segments of the market.
						</p>
					</div>
					<div className="lg:w-[30rem] relative">
						<div className="rotate-[5deg] lg:absolute bg-highblu lg:w-[30rem] lg:h-[16.8rem]"></div>
						<div className="-rotate-[5deg] lg:absolute bg-gray-400 lg:w-[30rem] lg:h-[16.8rem]"></div>
						{karibu && (
							<Carousel
								arrows
								autoPlay
								autoPlaySpeed={9000}
								infinite
								pauseOnHover
								slidesToSlide={1}
								swipeable
								responsive={responsive}
								showDots
								customDot={<CustomDot />}
								className="lg:absolute lg:w-[30rem] lg:h-[16.8rem]"
							>
								{karibu.karibuImage &&
									karibu.karibuImage
										.slice(1)
										.map(({ kImage }, idx) => (
											<img
												src={urlFor(kImage).url()}
												alt="banner_img"
												className="object-cover h-full w-full"
												key={idx}
											/>
										))}
							</Carousel>
						)}
					</div>
				</div>
			</div>
			<div className=" bg-blue-50 py-10 px-5 xl:px-0">
				<div className="max-w-4xl mx-auto flex justify-between gap-x-5">
					<img
						className="w-[7rem] h-[7rem] md:w-[8rem] object-cover lg:w-[10rem]"
						src={tangu_1954}
						alt="heritage_img"
					/>
					<div className="flex flex-col gap-y-2 lg:flex-row">
						<div className="">
							<h1 className="text-highblu font-bold text-4xl">
								The Why!
							</h1>
							<p className="lg:text-xl">
								We delight our customers with Great Tasting.
								Refreshing and Affordable Drinks where and when
								they want.
							</p>
						</div>
						<div className="lg:border-l border-l-gray-300 lg:px-3">
							<h1 className="text-highblu font-bold text-4xl">
								The How!
							</h1>
							<p className="lg:text-xl">
								By always Refreshing Kenyans with Joy... Tangu
								1954!
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="pt-10 px-5 xl:px-0">
				<div className="max-w-5xl mx-auto flex flex-col gap-y-2 lg:gap-y-0 items-center">
					<p className="text-2xl lg:text-4xl text-highblu font-bold leading-none lg:leading-normal">
						It is HOW we deliver what we do that really matters!
					</p>
					<p className="lg:w-3/4 lg:text-xl lg:text-center">
						To achieve our strategic priorities and desired
						performance. we have adopted a new and different
						culture; on that focuses on the Ways We Work, Leadership
						Attributes, Core Values, Ethics & compliance.
					</p>
				</div>
			</div>
			<div className="pb-10 relative lg:pb-[5rem]">
				<div className="h-full">
					<Features />

					{/* Sustainability */}
					<div className="flex flex-col justify-between pt-10 h-full">
						<div className="h-[20rem] lg:h-[30rem] w-full relative">
							<img
								src={bg}
								alt="sustainability_img"
								className="h-[20rem] lg:h-[30rem] w-full object-cover"
							/>
							<div className="absolute top-0 left-0 w-full mx-auto h-[20rem] lg:h-[30rem] bg-transparent grid place-items-center px-5 xl:px-0">
								<div className="flex flex-col items-center">
									<div className="text-white lg:w-3/4 flex flex-col text-center justify-center">
										<p className="font-semibold uppercase text-2xl lg:text-4xl xl:text-6xl 2xl:text-8xl mb-5 relative after:block after:bg-white after:absolute after:-bottom-3 after:left-[7.8rem] sm:after:left-56 lg:after:left-[15rem] xl:after:left-[20.5rem] 2xl:after:left-[15.5rem] 2xl:after:w-1/2 after:h-2 after:w-1/3">
											our Sustainability
										</p>
										<p className="text-sm sm:text-lg font-medium lg:text-xl">
											At Highlands&reg;, selecting only
											quality ingredients that respect our
											commitment to sustainability is a
											serious and continuous pledge.
											Discover more.
										</p>
									</div>
								</div>
							</div>
						</div>

						<div className="text-white lg:pb-[5rem] bg-[url('/src/static/bg.jpg')] bg-contain">
							<div className="flex flex-col lg:flex-row gap-y-5 py-[4rem] 2xl:gap-x-10 max-w-5xl mx-auto px-5 xl:px-0">
								<div className="lg:w-1/2 xl:mr-4">
									<h1 className="font-bold text-xl sm:text-2xl">
										Let's Talk About Sustainability.
									</h1>
									<p className="text-lg">
										We make brands and products that people
										love while building a more sustainable
										future for our business and for the
										planet.
										<br />
										<div className="h-[1rem]"></div> We do
										all of this while staying true to our
										purpose: to refresh the world and make a
										difference.
									</p>
								</div>
								<div className="lg:w-[30rem] relative">
									<div className="rotate-[5deg] lg:absolute bg-highblu lg:w-[30rem] lg:h-[16.8rem]"></div>
									<div className="-rotate-[5deg] lg:absolute bg-gray-400 lg:w-[30rem] lg:h-[16.8rem]"></div>
									<img
										className="lg:absolute lg:w-[30rem] lg:h-[16.8rem] object-cover"
										src={sustainability}
										alt="sustainability_img"
									/>
								</div>
							</div>
						</div>
						<Sustainability />
					</div>
				</div>
			</div>
			<Awards />
			<Fcta />
			<Links />
		</div>
	);
};

export default KaribuHighlands;
