import Brands from "../components/Brands";
import Showcase from "../components/Showcase";
import Welcome from "../components/Welcome";
import Awards from "../components/Awards";
import { useEffect } from "react";

const Home = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="">
      <Welcome />
      <Brands />
      <Showcase />
      <Awards />
    </div>
  );
};

export default Home;
