import { useEffect, useState } from "react";
import { PortableText, sanityClient, urlFor } from "../lib/sanity";
import { HFilledButton } from "./mini/HButton";
import { Card, CardActions, CardContent } from "@mui/material";

const Departments = () => {
  const [departments, setDepartments] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "department"]{
                name,
                slug,
                description,
                depIcon
            }`
      )
      .then((data) => setDepartments(data))
      .catch(console.error);
  }, []);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-y-5 lg:gap-y-0 gap-x-5 mt-[5rem] px-10 xl:px-0">
      {departments &&
        departments.map(({ name, slug, description, depIcon }, idx) => (
          <Card
            variant="outlined"
            className="flex flex-col justify-between items-center pt-3 pb-5 px-5 shadow-xl"
            key={idx}
          >
            <div className="flex flex-col items-center">
              <img
                src={urlFor(depIcon).url()}
                alt="hr_img"
                className="h-[5rem]"
              />
              <CardContent>
                <h1 className="font-semibold font-inter text-xl text-center">
                  {name}
                </h1>
                <div className="text-center text-sm text-gray-500">
                  <PortableText value={description} />
                </div>
              </CardContent>
            </div>
            <CardActions>
              <HFilledButton
                variant="contained"
                size="small"
                disableElevation
                href={`/workwithus/department/${slug.current}`}
              >
                View Openings
              </HFilledButton>
            </CardActions>
          </Card>
        ))}
    </div>
  );
};

export default Departments;
