import { ProductContext } from "../context/productContext";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { sanityClient, urlFor } from "../lib/sanity";
import { useState, useEffect } from "react";
import RelatedProduct from "../components/RelatedProduct";
import ProdIngrModal from "../components/ProdIngrModal";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CustomizedBreadcrumbs from "../components/mini/BreadCrumbs";
import Loader from "../components/mini/Loader";
import { PortableText } from "@portabletext/react";
import { serializers } from "../utils/serializers";
import { HButton } from "../components/mini/HButton";

const ProdDetails = () => {
  const { brand, slug } = useParams();
  const { dispatch } = useContext(ProductContext);
  const [details, setDetails] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "product" && slug.current == "${slug}"][0]{
				...,
				refBrand->,
				productsContent[]->{
          ...,
          description->
        },
				skus[]->
			}`
      )
      .then((data) => setDetails(data))
      .catch(console.error);
    window.scroll(0, 0);
  }, [slug]);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 768, min: 640 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  if (!details) {
    return <Loader />;
  }

  return (
    <div className="">
      <div className="flex flex-col gap-y-20 max-w-5xl mx-auto px-5 xl:px-0">
        <div>
          <CustomizedBreadcrumbs />
          {details && (
            <div className="flex flex-col lg:flex-row justify-center my-[5rem] gap-x-[4rem]">
              <Carousel
                arrows={details.productsContent.length > 1}
                infinite
                pauseOnHover
                slidesToSlide={1}
                swipeable
                responsive={responsive}
                className="w-full lg:w-[23rem] z-30 self-start"
              >
                {details.productsContent &&
                  details.productsContent.map(({ mainImage }, idx) => (
                    <div className="flex items-center justify-center" key={idx}>
                      <img
                        src={urlFor(mainImage).url()}
                        alt="prod_img"
                        className="h-[15rem] object-contain md:h-[30rem]"
                      />
                    </div>
                  ))}
              </Carousel>
              <div className="flex lg:w-1/2 flex-col lg:gap-y-4 mt-5">
                <div className="flex flex-col">
                  <p className="font-black font-inter capitalize text-lg md:text-3xl text-highblu leading-none text-opacity-80">
                    {details.refBrand.name}
                  </p>
                  <p className="font-black font-inter capitalize text-3xl md:text-5xl text-highor leading-none">
                    {details.title}
                  </p>
                  <div className="text-xs md:text-sm font-medium font-inter text-gray-700 py-3">
                    <PortableText
                      value={
                        details.productsContent[0].description
                          .flavourDescription[0]
                      }
                      components={serializers}
                    />
                  </div>
                  <HButton
                    onClick={() => {
                      dispatch({ type: "SHOWPRODMODAL" });
                    }}
                    sx={{ marginTop: "0.75rem" }}
                    variant="outlined"
                    className="w-max"
                  >
                    Nutritional Facts
                  </HButton>
                  <ProdIngrModal slug={slug} />
                </div>
                <div className="mt-2 lg:mt-0">
                  <p className="font-semibold text-base pb-3">Sizes (Litres)</p>
                  <div className="flex gap-x-1">
                    {details.skus &&
                      details.skus.map(({ skuName, mainImage }, idx) => (
                        <div
                          className="flex flex-col items-center justify-end"
                          key={idx}
                        >
                          <img
                            src={urlFor(mainImage).url()}
                            alt="product_sku"
                            className="h-min w-min"
                          />
                          <p className="pt-1 mx-auto text-[0.6rem]">
                            {skuName}
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className={
            brand !== "bazuu-energy" && brand !== "highlands-water"
              ? "max-w-6xl mx-auto w-full mb-5"
              : "hidden"
          }
        >
          <div className="flex justify-center items-center gap-x-3">
            <h1 className="text-center text-highblu text-xl lg:text-4xl py-5 font-inter font-black">
              More from
            </h1>
            {details && (
              <img
                src={urlFor(details.refBrand.mainImage).url()}
                alt="brand_image"
                className="h-[5rem]"
              />
            )}
          </div>
          <RelatedProduct />
        </div>
      </div>
    </div>
  );
};

export default ProdDetails;
