import React, { useEffect, useState } from "react";
import art_img from "../static/IMG_6300.jpg";
import { sanityClient, urlFor } from "../lib/sanity";
import { Link, useParams } from "react-router-dom";
import Loader from "../components/mini/Loader";
import moment from "moment";
import { PortableText } from "@portabletext/react";
import Carousel from "react-multi-carousel";
import CustomDot from "../utils/CustomDot";
import RelatedArticle from "../components/RelatedArticle";
import CustomizedBreadcrumbs from "../components/mini/BreadCrumbs";
import { serializers } from "../utils/serializers";
import { Avatar } from "@mui/material";
import { Person2Rounded } from "@mui/icons-material";
import { blue } from "@mui/material/colors";

const NewsDetails = () => {
	const { slug } = useParams();
	const [article, setArticle] = useState(null);

	useEffect(() => {
		sanityClient
			.fetch(
				`*[_type == "news" && slug.current == "${slug}"]{
					_updatedAt,
					_createdAt,
				title,
				slug,
				mainImage,
				articleImages,
				tag->,
				content
			}`
			)
			.then((data) => setArticle(data))
			.catch(console.error);
		window.scroll(0, 0);
	}, [slug]);

	if (!article) {
		return <Loader />;
	}

	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 1024 },
			items: 1,
			slidesToSlide: 1,
		},
		desktop: {
			breakpoint: { max: 1024, min: 768 },
			items: 1,
			slidesToSlide: 1,
		},
		tablet: {
			breakpoint: { max: 768, min: 640 },
			items: 1,
			slidesToSlide: 1,
		},
		mobile: {
			breakpoint: { max: 640, min: 0 },
			items: 1,
			slidesToSlide: 1,
		},
	};

	return (
		<>
			{article && (
				<div className="max-w-5xl lg:mx-auto mb-10">
					<div className="pb-5 px-5 xl:px-0">
						<CustomizedBreadcrumbs />
						<h1 className="font-bold text-2xl lg:text-4xl capitalize">
							{article[0].title}
						</h1>
						<div className="flex items-center gap-x-1 xl:gap-x-2 py-4 divide-x">
							<div className="flex items-center gap-x-2">
								<Avatar
									sx={{
										width: 30,
										height: 30,
										bgcolor: blue[800],
									}}
								>
									<Person2Rounded sx={{ fontSize: 22 }} />
								</Avatar>
								<span className="text-xs font-bold">Admin</span>
							</div>
							<div className="flex gap-x-3 items-center text-xs text-gray-600 pl-2">
								<p>
									{moment(article[0]._updatedAt).format(
										"DD/MM/YYYY"
									)}
								</p>
							</div>
							<Link
								to={`/news-events/${article[0].tag.slug.current}`}
							>
								<h1 className="text-center text-highor text-sm font-bold pl-2">
									{article[0].tag.name}
								</h1>
							</Link>
						</div>
					</div>
					<section className="px-5 xl:px-0">
						<img
							src={urlFor(article[0].mainImage).url()}
							alt="article_img"
							className="lg:h-[35rem] lg:w-full object-cover"
						/>
						<div className="lg:flex gap-x-10 mt-6">
							<div>
								<p className="whitespace-pre-line py-5 text-base flex-1 first-line:uppercase first-line:tracking-widest first-letter:font-black first-letter:text-7xl first-letter:float-left first-letter:mr-3 first-letter:text-highblu first-letter:leading-none">
									<PortableText
										value={article[0].content}
										components={serializers}
									/>
								</p>
								{article[0].articleImages && (
									<Carousel
										arrows
										autoPlay
										autoPlaySpeed={9000}
										infinite
										pauseOnHover
										slidesToSlide={1}
										swipeable
										responsive={responsive}
										showDots
										customDot={<CustomDot />}
										className="lg:w-[43rem] lg:h-[25rem] rounded-lg"
									>
										{article[0].articleImages.map(
											({ AImage }, idx) => (
												<img
													src={urlFor(AImage).url()}
													alt=""
													key={idx}
													className="object-cover max-w-full h-full"
												/>
											)
										)}
									</Carousel>
								)}
							</div>
							<div className="pt-5 shrink-0">
								<img
									src={art_img}
									alt=""
									className="hidden lg:block lg:w-[18.5rem]"
								/>
								<p className="font-bold uppercase text-sm border-t div-black mt-5 py-3">
									on the same topic
								</p>
								<RelatedArticle tag={article[0].tag.name} />
							</div>
						</div>
					</section>
				</div>
			)}
		</>
	);
};

export default NewsDetails;
