import { Button, styled } from "@mui/material";

export const HButton = styled(Button)(() => ({
  color: "#0055A5",
  borderColor: "#0055A5",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#0055A5",
    color: "#FFFFFF",
  },
}));

export const HFilledButton = styled(Button)(() => ({
  color: "#FFFFFF",
  backgroundColor: "#0055A5",
  borderColor: "#0055A5",
  textTransform: "none",
  "&:hover": {
    color: "#0055A5",
    borderColor: "#0055A5",
    backgroundColor: "#FFFFFF",
  },
}));
