import { Link } from "react-router-dom";
import { sanityClient, urlFor } from "../lib/sanity";
import { useState, useEffect } from "react";
import { Flip } from "react-reveal";

const Brands = () => {
  const [brands, setBrands] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "brand"] | order(hierachy asc){
				name,
				slug,
				brandImage,
				mainImage
			}`
      )
      .then((data) => setBrands(data))
      .catch(console.error);
  }, []);

  return (
    <div className="w-full">
      <div className="py-10 max-w-5xl mx-auto">
        <div className="py-4 flex flex-col items-center">
          <h1 className="font-inter text-center capitalize text-highblu font-black text-2xl lg:text-4xl">
            our leading brands
          </h1>
          {/* <h1 className="px-5 xl:px-0 text-center text-highblu text-xl lg:text-4xl font-inter font-semibold leading-tight">
						Delightfully Refreshing Kenya
						<span className="text-highor"> Tangu 1954!</span>
					</h1> */}
          {/* <p className="px-5 xl:px-0 lg:w-3/4 text-center text-xs lg:text-sm font-inter text-gray-600">
						At Highlands Drinks, Innovation is the backbone of our business. We
						focus on constant product and process innovation to always have the
						best taste at the best price.
					</p> */}
        </div>
        <div className="grid grid-cols-2 gap-y-4 md:grid-cols-5 gap-x-4 px-5 xl:px-0">
          {brands &&
            brands?.map(({ slug, brandImage }, idx) => (
              <Link
                to={`/products/${slug.current}`}
                className="relative overflow-hidden rounded-lg md:h-[12rem] transition-all duration-150 ease-linear shadow-2xl group"
                key={idx}
              >
                <img
                  src={urlFor(brandImage).url()}
                  alt="main_image"
                  className="object-cover w-full h-full rounded-lg"
                />
                <Flip left opposite>
                  <div className="absolute bottom-0 grid md:hidden md:-bottom-[10rem] w-full h-full bg-transparent group-hover:grid place-items-center group-hover:-bottom-0">
                    <img src={urlFor(brandImage).url()} alt="brand_logo" />
                  </div>
                </Flip>
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Brands;
