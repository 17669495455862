import { createContext, useReducer } from "react";
import brandReducer from "./brandReducer";

const INITIAL_STATE = {
	showBrandMenu: false,
};

export const BrandContext = createContext(INITIAL_STATE);

export const BrandContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(brandReducer, INITIAL_STATE);

	return (
		<BrandContext.Provider
			value={{
				showBrandMenu: state.showBrandMenu,
				dispatch,
			}}>
			{children}
		</BrandContext.Provider>
	);
};
