import { useContext, useState } from "react";
import scase from "../static/Background.jpg";
import Discovery from "./Discovery";
import News from "./News";
import Events from "./Events";
import { DiscoveryContext } from "../context/discoveryContext";

const Showcase = () => {
  const { dispatch } = useContext(DiscoveryContext);
  const [active, setActive] = useState("Karibu");

  const handleNews = () => {
    dispatch({ type: "NEWS" });
    setActive("News");
  };

  const handleDiscovery = () => {
    dispatch({ type: "DISCOVERY" });
    setActive("Karibu");
  };

  const handleEvents = () => {
    dispatch({ type: "EVENTS" });
    setActive("Events");
  };

  return (
    <div className="lg:mb-[12rem] w-full relative">
      <img
        src={scase}
        alt="banner"
        className="hidden lg:flex h-[35rem] w-full object-cover"
      />
      <div className="lg:absolute lg:top-0 w-full bg-transparent grid place-items-center">
        <div className="max-w-3xl mx-auto w-full py-10 flex flex-col gap-y-8 lg:gap-y-12">
          <div className="flex items-center justify-between sm:justify-center sm:gap-x-20 md:justify-between w-screen lg:w-full font-inter px-5 xl:px-0">
            <div
              onClick={handleNews}
              className="flex flex-col items-center lg:text-white leading-none cursor-pointer"
            >
              <p
                className={
                  active === "News"
                    ? "font-semibold text-lg lg:text-2xl transition-all duration-300 ease-in-out"
                    : "font-semibold text-xs lg:text-lg transition-all duration-300 ease-in-out"
                }
              >
                Discover
              </p>
              <p
                className={
                  active === "News"
                    ? "lg:text-4xl text-xl font-bold text-highor transition-all duration-300 ease-in-out"
                    : "text-sm lg:text-2xl font-bold text-blue-600 transition-all duration-300 ease-in-out"
                }
              >
                The News
              </p>
            </div>
            <div
              onClick={handleDiscovery}
              className="flex flex-col items-center lg:text-white leading-none cursor-pointer"
            >
              <p
                className={
                  active === "Karibu"
                    ? "font-semibold text-lg lg:text-2xl transition-all duration-300 ease-in-out"
                    : "font-semibold text-xs lg:text-lg transition-all duration-300 ease-in-out"
                }
              >
                Karibu
              </p>
              <p
                className={
                  active === "Karibu"
                    ? "lg:text-4xl text-xl font-bold text-highor transition-all duration-300 ease-in-outr"
                    : "lg:text-2xl text-sm font-bold text-blue-600 transition-all duration-300 ease-in-out"
                }
              >
                Highlands
              </p>
            </div>
            <div
              onClick={handleEvents}
              className="flex flex-col items-center lg:text-white leading-none cursor-pointer"
            >
              <p
                className={
                  active === "Events"
                    ? "font-semibold text-lg lg:text-2xl transition-all duration-300 ease-in-out"
                    : "font-semibold text-xs lg:text-lg transition-all duration-300 ease-in-out"
                }
              >
                Our
              </p>
              <p
                className={
                  active === "Events"
                    ? "lg:text-4xl text-xl font-bold text-highor transition-all duration-300 ease-in-out"
                    : "text-sm lg:text-2xl font-bold text-blue-600 transition-all duration-300 ease-in-out"
                }
              >
                Events
              </p>
            </div>
          </div>
          <Discovery />
          <Events />
          <News />
        </div>
      </div>
    </div>
  );
};

export default Showcase;
