import { Link } from "react-router-dom";
import { RiArrowRightUpLine } from "react-icons/ri";
import { PortableText, sanityClient, urlFor } from "../lib/sanity";
import { useState, useEffect } from "react";
import moment from "moment";
import { Avatar, Chip } from "@mui/material";
import NoJob from "./mini/NoJob";

const Job = () => {
  const [jobs, setJobs] = useState(null);
  const nowDate = new Date();
  const today = nowDate.toISOString();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "job"]{
				title,
				slug,
				department->,
				description,
				fullDescription,
        deadline,
				type[] ->
			}`
      )
      .then((data) => setJobs(data))
      .catch(console.error);
  }, []);

  return (
    <div>
      {jobs &&
        jobs
          .filter((job) => job.deadline >= today)
          .map(({ title, slug, description, type, deadline }, idx) => (
            <Link to={`/workwithus/${slug.current}`}>
              <div
                className="border-t space-y-3 border-t-gray-400 px-2 py-[1rem] mt-4 hover:bg-gray-200 transition-colors duration-300 ease-in-out"
                key={idx}
              >
                <div className="flex items-center justify-between">
                  <h1 className="font-bold font-inter text-xl lg:text-2xl capitalize">
                    {title}
                  </h1>
                  <Link
                    to={`/workwithus/${slug.current}`}
                    className="hidden md:flex items-center font-bold font-inter text-sm lg:text-2xl hover:text-highblu hover:underline transition-colors duration-200 ease-out"
                  >
                    Apply <RiArrowRightUpLine />
                  </Link>
                </div>
                <div className="font-medium text-sm lg:text-base">
                  <PortableText value={description} />
                </div>
                <div className="flex flex-col md:flex-row gap-y-2 md:space-y-0 md:items-center justify-between">
                  <div className="flex items-center gap-x-3">
                    {type &&
                      type.map(({ name, icon }, idx) => (
                        <Chip
                          avatar={
                            <Avatar alt="icon" src={urlFor(icon).url()} />
                          }
                          label={name}
                          variant="outlined"
                          size="small"
                          key={idx}
                        />
                      ))}
                  </div>

                  <div className="">
                    <p className="text-sm font-medium underline">
                      Deadline -{" "}
                      {moment(deadline).format("MMMM Do YYYY, h:mm A")}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          ))}

      {(jobs?.filter((job) => job.deadline >= today).length === 0 ||
        jobs?.length === 0) && <NoJob />}
    </div>
  );
};

export default Job;
