import React from "react";
import artboard from "../static/General-Footer.png";

const Sig = () => {
	return (
		<div className="">
			<img src={artboard} alt="Highlands Signature" className="w-full h-full" />
		</div>
	);
};

export default Sig;
