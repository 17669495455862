import { GoogleMap, Marker } from "@react-google-maps/api";
import { useMemo } from "react";

const Map = () => {
	const center = useMemo(
		() => ({ lng: 36.944475322801274, lat: -0.41274340219334654 }),
		[]
	);
	return (
		<GoogleMap zoom={15} center={center} mapContainerClassName="map-container">
			<Marker position={center} title="Highlands Drinks Limited" />
		</GoogleMap>
	);
};

export default Map;
