import { Card, CardActions, CardContent } from "@mui/material";
import React from "react";
import { HButton } from "./HButton";
import { urlFor } from "../../lib/sanity";

const ProductCard = ({ sumImage, title, refBrand, slug }) => {
  return (
    <Card sx={{ maxWidth: 345, boxShadow: "none" }}>
      <div className="flex flex-col justify-between gap-y-[2rem] pt-[4rem] bg-white items-center py-5 group h-[19rem] lg:h-[27.3rem]">
        <img
          src={urlFor(sumImage).url()}
          alt="product_img"
          className="object-cover h-[8rem] lg:h-[15rem] scale-125 mx-auto"
        />
        <div className="flex flex-col items-center">
          <CardContent sx={{ paddingY: 0 }}>
            <h1 className="text-center font-black uppercase text-xs lg:text-sm">
              {title}
            </h1>
          </CardContent>
          <CardActions>
            <HButton
              variant="outlined"
              sx={{
                paddingX: "1.75rem",
                fontWeight: 900,
              }}
              size="small"
              href={`/products/${refBrand.slug.current}/${slug.current}`}
            >
              Explore
            </HButton>
          </CardActions>
        </div>
      </div>
    </Card>
  );
};

export default ProductCard;
