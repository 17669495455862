import { memo } from "react";
// import promo from "../static/promo.jpg";
import {
	FaFacebookF,
	FaInstagram,
	FaLinkedinIn,
	FaTiktok,
} from "react-icons/fa";

const Sidebar = ({ brand }) => {
	return (
		<div className="max-w-5xl mx-auto mt-10 lg:mt-0 2xl:mr-0 lg:w-1/6">
			<div className="bg-highblu text-white p-4">
				{/* <button className="bg-gray-300 p-1 w-full font-semibold text-drkblu font-inter text-sm">
          Download Catalog
        </button> */}
				<div className="flex items-center lg:flex-col py-3">
					<p className="font-bold text-sm">Let's Get Social:</p>
					<div className="flex gap-x-2 py-1">
						<a
							href={
								brand === "club"
									? "https://www.facebook.com/ClubSodaKe"
									: brand === "bazuu"
									? "https://www.facebook.com/bazuuenergy"
									: brand === "royal"
									? "https://www.facebook.com/royalclubmixers"
									: "https://www.facebook.com/HighlandsDrinks"
							}
							target="_blank"
							rel="noreferrer"
							className="hover:-translate-y-0.5 transition-all duration-150 ease-linear"
						>
							<FaFacebookF />
						</a>
						<a
							href={
								brand === "club"
									? "https://www.instagram.com/clubsodake/"
									: brand === "bazuu"
									? "https://www.instagram.com/bazuuenergydr/"
									: brand === "royal"
									? "https://www.instagram.com/royalclubmixers/"
									: "https://www.instagram.com/highlandsdrinks/"
							}
							target="_blank"
							rel="noreferrer"
							className="hover:-translate-y-0.5 transition-all duration-150 ease-linear"
						>
							<FaInstagram />
						</a>
						<a
							href="https://www.linkedin.com/company/highlands-drinks-limited/"
							target="_blank"
							rel="noreferrer"
							className="hover:-translate-y-0.5 transition-all duration-150 ease-linear"
						>
							<FaLinkedinIn />
						</a>
						{(brand === "club" || brand === "bazuu") && (
							<a
								href={
									brand === "club"
										? "https://www.tiktok.com/@clubsodake?_t=8iOr1kVrNUT&_r=1"
										: "https://www.tiktok.com/@bazuuenergydr?_t=8iOrARDwlZl&_r=1"
								}
								target="_blank"
								rel="noreferrer"
								className="hover:-translate-y-0.5 transition-all duration-150 ease-linear"
							>
								<FaTiktok />
							</a>
						)}
					</div>
				</div>
			</div>

			{/* <div className="my-5 space-y-3">
        <h1 className="text-drkblu font-bold text-sm font-inter">
          Latest News:
        </h1>
        <img src={promo} className="h-full" alt="promo_img" />
      </div>

      <div className="space-y-3">
        <h1 className="text-drkblu font-bold text-sm font-inter">
          Instagram Feed:
        </h1>
        <div className="bg-highblu h-[20rem]"></div>
      </div> */}
		</div>
	);
};

export default memo(Sidebar);
