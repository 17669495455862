import { createContext, useReducer } from "react";
import faqReducer from "./faqReducer";

const INITIAL_STATE = {
	showFAQ: false,
};

export const FaqContext = createContext(INITIAL_STATE);

export const FaqContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(faqReducer, INITIAL_STATE);

	return (
		<FaqContext.Provider
			value={{
				showFAQ: state.showFAQ,
				dispatch,
			}}>
			{children}
		</FaqContext.Provider>
	);
};
