import { memo } from "react";
import nothing from "../../static/nothing_found.svg";
import { Card, CardContent } from "@mui/material";

const NoJob = () => {
  return (
    <section>
      <Card className="lg:w-3/4 mx-auto my-5" variant="outlined">
        <div className="text-center space-y-5">
          <img
            src={nothing}
            alt="nothing_found"
            className="w-[10rem] mx-auto"
          />
          <CardContent>
            <h1 className="capitalize font-bold font-inter text-xl lg:text-5xl text-highor">
              No opportunities for now!
            </h1>
            <p className="font-medium text-sm lg:text-lg font-inter lg:w-3/4 mx-auto text-gray-600">
              Keep checking this page for updates on more opportunities.
            </p>
          </CardContent>
        </div>
      </Card>
    </section>
  );
};

export default memo(NoJob);
