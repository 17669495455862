import { Link, useLocation } from "react-router-dom";
import BrandMenu from "./BrandMenu";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { useContext } from "react";
import { BrandContext } from "../context/brandContext";
import { useEffect, memo, useRef } from "react";

const Navbar = () => {
	const location = useLocation();
	const { showBrandMenu, dispatch } = useContext(BrandContext);
	const url = location.pathname.split("/")[1];
	const menuRef = useRef(null);

	const handleMenu = () => {
		dispatch({ type: "SHOWBRANDNAV" });
	};

	useEffect(() => {
		let handler = (e) => {
			if (!menuRef.current.contains(e.target)) {
				dispatch({ type: "HIDEBRANDNAV" });
			}
		};

		document.addEventListener("mousedown", handler);

		return () => {
			document.removeEventListener("mousedown", handler);
		};
	}, [dispatch]);

	return (
		<div className="text-white hidden lg:flex lg:flex-1 justify-center">
			<nav className="flex items-center gap-x-5 text-xs">
				<Link
					to="/"
					className={
						url === ""
							? "font-bold text-highor relative after:block after:bg-highor after:absolute after:-bottom-4 after:left-0 after:h-1 after:w-full after:rounded-xl"
							: "font-semibold"
					}
				>
					Home
				</Link>
				<div
					className={
						url === "products"
							? "font-semibold text-highor relative after:block after:bg-highor after:absolute after:-bottom-4 after:left-0 after:h-1 after:w-full after:rounded-xl"
							: "relative font-semibold"
					}
				>
					<button
						className="flex items-center gap-x-1 cursor-pointer font-semibold"
						onClick={handleMenu}
					>
						Products
						{!showBrandMenu ? (
							<BiChevronDown size={20} />
						) : (
							<BiChevronUp size={20} />
						)}
					</button>
					<BrandMenu menuRef={menuRef} />
				</div>
				<Link
					to="/karibu-highlands"
					className={
						url === "karibu-highlands"
							? "font-semibold text-highor relative after:block after:bg-highor after:absolute after:-bottom-4 after:left-0 after:h-1 after:w-full after:rounded-xl"
							: "font-semibold"
					}
				>
					About Us
				</Link>
				<Link
					to="/our-heritage"
					className={
						url === "our-heritage"
							? "font-semibold text-highor relative after:block after:bg-highor after:absolute after:-bottom-4 after:left-0 after:h-1 after:w-full after:rounded-xl"
							: "font-semibold"
					}
				>
					Our Heritage
				</Link>
				<Link
					to="/workwithus"
					className={
						url === "workwithus"
							? "font-semibold text-highor relative after:block after:bg-highor after:absolute after:-bottom-4 after:left-0 after:h-1 after:w-full after:rounded-xl"
							: "font-semibold"
					}
				>
					Careers
				</Link>
				<Link
					to="news-events"
					className={
						url === "news-events"
							? "font-semibold text-highor relative after:block after:bg-highor after:absolute after:-bottom-4 after:left-0 after:h-1 after:w-full after:rounded-xl"
							: "font-semibold"
					}
				>
					News & Events
				</Link>
				<Link
					to="/FAQs"
					className={
						url === "FAQs"
							? "uppercase font-semibold text-highor relative after:block after:bg-highor after:absolute after:-bottom-4 after:left-0 after:h-1 after:w-full after:rounded-xl"
							: "uppercase font-semibold"
					}
				>
					FAQ
				</Link>
				<Link
					to="/contactus"
					className={
						url === "contactus"
							? "font-semibold text-highor relative after:block after:bg-highor after:absolute after:-bottom-4 after:left-0 after:h-1 after:w-full after:rounded-xl"
							: "font-semibold"
					}
				>
					Contact Us
				</Link>
			</nav>
		</div>
	);
};

export default memo(Navbar);
