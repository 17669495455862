import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PortableText } from "../../lib/sanity";

export default function FAQAccordion({ question, answer, idx }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      expanded={expanded === `panel_${idx}`}
      onChange={handleChange(`panel_${idx}`)}
      key={idx}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panelbh-content"
        id="panelbh-header"
      >
        <Typography variant="subtitle1" className="capitalize">
          {question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className="text-highblu text-md">
          <PortableText value={answer} />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
