import contact from "../static/contact-Slide.jpg";
import contact_img from "../static/office_phone.svg";
// import Map from "../components/Map";
// import { useLoadScript } from "@react-google-maps/api";
import emailjs from "@emailjs/browser";
import { useRef } from "react";
import Swal from "sweetalert2";
import { IoCopyOutline } from "react-icons/io5";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { useState } from "react";
import { TextField } from "@mui/material";
import { HButton } from "../components/mini/HButton";

const Contact = () => {
  const form = useRef();
  const customerRef = useRef(null);
  const highlandsRef = useRef(null);
  const captchaRef = useRef(null);
  const [data, setData] = useState(null);

  // const emailInput = form.current.from_email.value;

  // const { isLoaded } = useLoadScript({
  // 	googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  // });

  // if (!isLoaded) return <div>...</div>;

  // const validateEmail = (iEmail) => {
  // 	// Regular expression pattern for email validation
  // 	const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // 	return pattern.test(iEmail);
  // };

  const sendEmail = async (e) => {
    e.preventDefault();
    const captchaValue = captchaRef.current.getValue();

    if (!captchaValue) {
      Swal.fire({
        icon: "warning",
        title: "Verify CAPTCHA",
        text: "Please verify the reCAPTCHA before submitting the form.",
      });
    } else {
      await axios
        .post("https://hdl-recaptcha.vercel.app/verify", {
          captchaValue,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
          },
        })
        .then((res) => setData(res.data))
        .catch((err) => {
          console.log(err);
        });

      if (data?.success) {
        emailjs
          .sendForm(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            form.current,
            process.env.REACT_APP_EMAILJS_API_KEY
          )
          .then(
            (result) => {
              result.text === "OK" &&
                Swal.fire({
                  icon: "success",
                  title: "Message Sent Successfully",
                });
              e.target.reset();
            },
            // TODO:Abstract Error Message from client
            (error) => {
              error &&
                Swal.fire({
                  icon: "error",
                  title: "Ooops, something went wrong",
                  text: error.text,
                });
            }
          );
      } else {
        Swal.fire({
          icon: "error",
          title: "Ooops, something went wrong",
          text: "reCAPTCHA validation failed! Please try again.",
        });
      }
      captchaRef.current.reset();
    }
  };

  return (
    <div className="">
      <div className="h-[20rem] sm:h-[30rem] w-full relative">
        <img
          src={contact}
          alt="contact_img"
          className="h-[20rem] sm:h-[30rem] object-cover w-full"
          loading="eager"
        />
        <div className="absolute top-0 left-0 w-full h-[20rem] sm:h-[30rem] bg-transparent grid place-items-center">
          <p className="uppercase font-black text-2xl lg:text-4xl xl:text-6xl 2xl:text-8xl text-center text-white mb-5 z-10 relative after:block after:bg-white after:absolute after:-bottom-3 after:left-7 lg:after:left-[2.5rem] xl:after:left-[4rem] 2xl:after:left-[9.5rem] 2xl:after:w-1/2 after:h-2 after:w-2/3">
            Contact us
          </p>
        </div>
      </div>
      <div className="max-w-5xl mx-auto py-[3rem]">
        <div className="px-5 xl:px-0">
          <p className="font-black font-inter text-center text-highblu text-3xl lg:text-5xl mx-auto">
            Get in touch with us
          </p>
          <p className="text-center font-medium lg:text-xl text-gray-600">
            If you need help or have a question, we are here for you.
          </p>
        </div>
        <div className="lg:mt-[3rem] bg-gray-100 p-10 shadow-2xl shadow-gray-200">
          <div className="flex flex-col lg:flex-row gap-x-5">
            <img
              src={contact_img}
              alt="contact_img"
              className="lg:h-[25rem] object-cover"
            />
            <form ref={form} onSubmit={sendEmail} className="w-full space-y-6">
              <div className="lg:flex space-y-6 lg:space-y-0 gap-x-4 w-full">
                <div className="flex flex-col gap-y-2 flex-1">
                  <TextField
                    id="firstname"
                    name="first_name"
                    label="First Name"
                    variant="outlined"
                    size="small"
                    required
                  />
                </div>
                <div className="flex flex-col gap-y-2 flex-1">
                  <TextField
                    id="lastname"
                    name="last_name"
                    label="Last Name"
                    variant="outlined"
                    size="small"
                    required
                  />
                </div>
              </div>
              <div className="flex flex-col gap-y-2">
                <TextField
                  id="email"
                  name="from_email"
                  label="Email"
                  variant="outlined"
                  size="small"
                  required
                />
              </div>
              <div className="flex flex-col gap-y-2">
                <TextField
                  id="subject"
                  name="subject"
                  label="Subject"
                  variant="outlined"
                  size="small"
                  required
                />
              </div>
              <div className="flex flex-col gap-y-2">
                <TextField
                  id="message"
                  name="message"
                  label="Message"
                  multiline
                  minRows={6}
                  required
                />
              </div>
              <ReCAPTCHA
                ref={captchaRef}
                sitekey={process.env.REACT_APP_SITE_KEY}
              />
              <HButton type="submit" variant="outlined">
                Get in Touch
              </HButton>
            </form>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 lg:gap-10 py-6 font-inter">
            <div className="rounded-xl bg-white py-7 px-10">
              <p className="font-semibold capitalize py-2">HQ office</p>
              <div className="text-highblu">
                <p className="font-medium text-sm capitalize">ihururu road,</p>
                <p className="font-medium text-sm capitalize">
                  PO Box 1517-10100 Nyeri, Kenya
                </p>
              </div>
              <div className="text-sm font-medium text-gray-600 py-2">
                <p>Nyeri office landlines:</p>
                <p>+254 (0) 61 2032381/2032296</p>
              </div>
            </div>
            <div className="rounded-xl bg-white py-7 px-10">
              <p className="font-semibold capitalize py-2">nairobi depot</p>
              <p className="font-medium text-sm capitalize text-highblu">
                atlantis business park, kyangombe
              </p>
              <div className="text-sm font-medium text-gray-600 py-2">
                <p>Nairobi office landlines:</p>
                <p>+254 (0) 727 556677</p>
                <p>+254 (0) 706 330739/627</p>
              </div>
            </div>
            <div className="rounded-xl bg-white py-7 px-10">
              <p className="font-semibold capitalize py-2">
                customer care line
              </p>
              <p className="font-medium text-sm capitalize text-highblu">
                (Call/Whatsapp) 0790490529
              </p>
              <div className="text-sm font-medium text-gray-600 py-2">
                <p>Available Mon - Fri: 8am - 5pm</p>
                <p>Sat: 8am - 1pm</p>
              </div>
            </div>
            <div className="rounded-xl bg-white py-7 px-10">
              <p className="font-semibold capitalize py-2">email</p>
              <div className="text-sm font-medium text-gray-600 py-2">
                <div className="flex items-center gap-x-3 group">
                  <p className="hover:text-highblu">
                    <a
                      href="mailto:customercare@highlandske.com"
                      ref={customerRef}
                    >
                      customercare@highlandske.com
                    </a>
                  </p>
                  <IoCopyOutline
                    className="hidden group-hover:flex cursor-pointer hover:text-highor"
                    title="Copy Email"
                    onClick={() =>
                      navigator.clipboard.writeText(
                        customerRef.current.innerText
                      )
                    }
                  />
                </div>
                <div className="flex items-center gap-x-3 group">
                  <p className="hover:text-highblu">
                    <a
                      href="mailto:highlands@highlandske.com"
                      ref={highlandsRef}
                    >
                      highlands@highlandske.com
                    </a>
                  </p>
                  <IoCopyOutline
                    className="hidden group-hover:flex cursor-pointer hover:text-highor"
                    title="Copy Email"
                    onClick={() =>
                      navigator.clipboard.writeText(
                        highlandsRef.current.innerText
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="my-[3rem] contact">
          <Map />
        </div> */}
      </div>
    </div>
  );
};

export default Contact;
